import React, { useContext } from 'react';
import { AlertColor, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import { UserContext } from '../context/UserContext';

const ServerAlertMessage = ({
  type = 'error',
  message,
  isLoading,
  refetch,
  alertRef,
}: {
  type?: AlertColor;
  message: string;
  isLoading: boolean;
  refetch: () => void;
  alertRef?: any;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { locale } = useContext(UserContext);

  return (
    <AlertMessage
      isServerAlert
      styles={{ marginBottom: 2 }}
      type={type}
      iconAlignment="baseline"
      alertRef={alertRef}
      locale={locale}
      theme={theme}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 0,
        }}
      >
        <Typography sx={{ color: theme.palette[type]['160p'] }}>{message}</Typography>

        {type === 'error' && isLoading && (
          <CircularProgress
            aria-label="Loading Data"
            aria-live="polite"
            size="24px"
            aria-busy={isLoading}
            data-testid="loading-icon"
            sx={{ color: 'text.secondary', marginLeft: '16px', padding: '2px' }}
          />
        )}
        {type === 'error' && !isLoading && (
          <Button
            sx={{
              color: `${theme.palette.error['160p']}`,
              padding: 0,
              ':hover': {
                backgroundColor: 'transparent',
                color: 'text.primary',
              },
            }}
            onClick={() => refetch()}
          >
            {t(`actions.retry`)}
          </Button>
        )}
      </Box>
    </AlertMessage>
  );
};

export default ServerAlertMessage;
