import React, { useContext } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  Typography,
} from '@mui/material';
import { useAuth } from 'oidc-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Error } from '../../img/error.svg';
import { Step } from '../../types';
import { UserContext } from '../../context/UserContext';

const NoSteps = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useAuth();
  const { apiFetch } = useContext(UserContext);

  document.title = t('app.pageTitle', { name: t('nav.noSteps') });
  const { isLoading } = useQuery<Step[]>(
    'steps',
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/steps`,
        false
      ),
    {
      staleTime: 0,
      onSuccess(data: any) {
        if (data && data.length > 0) navigate('/', { replace: true });
      },
    }
  );

  return isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '96px',
      }}
    >
      <CircularProgress
        aria-label="Loading Data"
        aria-live="polite"
        aria-busy={isLoading}
        data-testid="loading-icon"
        sx={{ color: 'text.secondary' }}
      />
    </Box>
  ) : (
    <Card
      variant="outlined"
      sx={{
        textAlign: 'center',
      }}
    >
      <>
        <CardContent
          sx={{
            padding: 5,
            paddingBottom: 0,
          }}
        >
          <Error />
          <CardHeader
            title={t('screens.noSteps.title')}
            titleTypographyProps={{ variant: 'h1' }}
            sx={{
              paddingBottom: 0,
              marginBottom: 3,
            }}
          />
          <Typography variant="h2" mb={2}>
            {t('screens.noSteps.subtitle')}
          </Typography>
          <Typography>{t('screens.noSteps.content')}</Typography>
        </CardContent>
        {sessionStorage.getItem('disableNav') !== 'true' && (
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: 5,
            }}
          >
            <Button
              component={Link}
              href={process.env.REACT_APP_LINK_BACK_OFFICE}
              target="_self"
              color="primary"
              variant="contained"
              disableElevation
            >
              {t('screens.noSteps.goToBackoffice')}
            </Button>
          </CardActions>
        )}
      </>
    </Card>
  );
};

export default NoSteps;

