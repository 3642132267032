import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import {
  Card,
  Box,
  Typography,
  CircularProgress,
  Grid,
  TablePagination,
  Pagination,
  Link,
  Button,
  useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useQuery } from 'react-query';
import { AlertMessage, SnackBarMessage } from '@eposnow/ui-core';
import ErrorLoadingData from '../../components/ErrorLoadingData';
import { useSteps } from '../../components/StepController';
import { Address, AddressOption } from '../addresses/types/addressTypes';
import { TerminalType } from '../../types';
import Terminal from './Terminal';
import ServerAlertMessage from '../../components/ServerAlertMessage';
import { TERMINALS_PER_PAGE } from '../../constants';
import { ReactComponent as LocationPin } from '../../img/icons/location-pin.svg';
import { ReactComponent as WhiteLocationPin } from '../../img/icons/location-pin-white.svg';
import { UserContext } from '../../context/UserContext';
import { UIContext } from '../../context/UIContext';

const TerminalStep = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { apiFetch, locale } = useContext(UserContext);
  const { colorMode, isMobile } = useContext(UIContext);

  document.title = t('app.pageTitle', { name: t('nav.terminal') });

  const stepContext = useSteps();
  const [addresses, setAddresses] = useState<AddressOption[]>([]);
  const [showAlertMessageAddresses, setShowAlertMessageAddresses] = useState(false);
  const [successMessageUpdateTerminal, setSuccessMessageUpdateTerminal] = useState<string>('');
  const [terminalsCurrentPage, setTerminalsCurrentPage] = useState<TerminalType[]>([]);
  const [isSlicingTerminals, setIsSlicingTerminals] = useState(true);
  const [page, setPage] = useState(1);
  const [terminalsPerPage, setTerminalsPerPage] = React.useState(TERMINALS_PER_PAGE);

  const handleChangePage = (event: ChangeEvent<unknown> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeTerminalsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTerminalsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const messageRef = useRef<null | HTMLElement>(null);

  const {
    refetch: fetchAddresses,
    data: dataAddresses,
    isLoading: isLoadingAddresses,
  } = useQuery<Address[]>(
    ['addresses'],
    () => apiFetch(`${process.env.REACT_APP_API_V4}location`),
    {
      staleTime: 0,
      onSuccess: () => setShowAlertMessageAddresses(false),
      onError: (err: any) => {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error fetching addresses'), {
          extra: { error: JSON.stringify(err) },
        });
        setShowAlertMessageAddresses(true);
      },
    }
  );

  useEffect(() => {
    if (dataAddresses) {
      setAddresses(
        dataAddresses.map((address: Address) => ({
          value: address.Id.toString(),
          label: address.Name,
        }))
      );
    }
  }, [dataAddresses]);

  const {
    refetch: fetchTerminals,
    data: terminals,
    isLoading,
    isError,
    error,
  } = useQuery<TerminalType[]>(
    ['terminals'],
    () =>
      apiFetch(`${process.env.REACT_APP_API_PAYMENTS}v1/payments/terminal/get-all-terminals`, true),
    {
      staleTime: 0,
      onSuccess(data: TerminalType[]) {
        if (stepContext?.updateCurrentStep)
          stepContext.updateCurrentStep({
            error: '',
            completable: data.length > 0 && data.filter((e) => e.LocationId == null).length === 0,
          });
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error fetching terminals'), {
          extra: { error: JSON.stringify(err) },
        });
        setIsSlicingTerminals(false);
        if (stepContext?.updateCurrentStep) stepContext.updateCurrentStep({ error: '' });
      },
    }
  );

  useEffect(() => {
    if (!terminals || !stepContext?.updateCurrentStep) return;

    stepContext.updateCurrentStep({
      error: '',
      completable:
        terminals.length > 0 && terminals.filter((e) => e.LocationId == null).length === 0,
    });
  }, [terminals]);

  useEffect(() => {
    if (!terminals) return;
    if (Array.isArray(terminals))
      setTerminalsCurrentPage(
        terminals.slice(
          (page - 1) * terminalsPerPage,
          (page - 1) * terminalsPerPage + terminalsPerPage
        )
      );
    setIsSlicingTerminals(false);
  }, [page, terminalsPerPage, terminals]);

  const updateStepError = (message: string) => {
    if (stepContext?.currentStep?.error && stepContext?.updateCurrentStep)
      stepContext.updateCurrentStep({
        error: message,
      });
  };

  const unauthorized = (error as any)?.code === 401;

  const defaultLabelDisplayedTerminals = ({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) => t('screens.terminal.fromToTerminals', { from, to, count });

  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: 2,
        padding: { xs: 2, sm: 3 },
      }}
    >
      {isLoading || isSlicingTerminals || unauthorized ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '96px',
          }}
        >
          <CircularProgress
            aria-label="Loading Data"
            aria-live="polite"
            aria-busy={isLoading || isSlicingTerminals || unauthorized}
            data-testid="loading-icon"
            sx={{ color: 'text.secondary' }}
          />
        </Box>
      ) : (
        <>
          <SnackBarMessage
            message={successMessageUpdateTerminal}
            setMessage={setSuccessMessageUpdateTerminal}
            isMobile={isMobile}
            locale={locale}
            theme={theme}
          />
          {showAlertMessageAddresses && (
            <ServerAlertMessage
              message={t(`errors.errorLoadingAddresses`)}
              isLoading={isLoadingAddresses}
              refetch={fetchAddresses}
              alertRef={messageRef}
            />
          )}
          {isError && <ErrorLoadingData action={fetchTerminals} />}
          {!isError && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 3,
              }}
            >
              {colorMode === 'light' ? <LocationPin /> : <WhiteLocationPin />}
              <Typography variant="h2" marginY={2}>
                {t('screens.terminal.terminalLocation')}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                {t('screens.terminal.terminalLocationDescription')}
              </Typography>
              <Box sx={{ marginTop: 4, width: '100%' }}>
                {terminalsCurrentPage.length > 0 && (
                  <Grid container spacing={{ xs: 1, sm: 3 }}>
                    {terminalsCurrentPage.map((terminal) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        display="flex"
                        key={`terminal-${terminal.SerialNumber}`}
                      >
                        <Terminal
                          addresses={addresses}
                          isLoadingAddresses={isLoadingAddresses}
                          terminalData={terminal}
                          updateStepError={updateStepError}
                          setSuccessMessageUpdateTerminal={setSuccessMessageUpdateTerminal}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Box sx={{ width: '100%', marginTop: 2 }}>
                  {terminalsCurrentPage.length === 0 ? (
                    <AlertMessage
                      type="warning"
                      iconAlignment="baseline"
                      locale={locale}
                      theme={theme}
                    >
                      {t('screens.terminal.noTerminals')}
                    </AlertMessage>
                  ) : (
                    <AlertMessage
                      isServerAlert
                      styles={{ marginBottom: 2, padding: { xs: '4px 8px', md: '8px 16px' } }}
                      type="warning"
                      iconAlignment="baseline"
                      locale={locale}
                      theme={theme}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'space-between',
                          flexDirection: { xs: 'column', md: 'row' },
                        }}
                      >
                        <Box
                          sx={{
                            justifyContent: 'space-between',
                            maxWidth: { md: '75%', lg: '80%' },
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 600,
                              lineHeight: 1.5,
                              marginTop: '-2px',
                              marginBottom: '4px',
                            }}
                          >
                            <Trans i18nKey="steps.tutorials.alerts.warningTitle" />
                          </Typography>
                          <Typography variant="body2">
                            <Trans i18nKey="screens.terminal.terminalsHaventArrived" />
                          </Typography>
                        </Box>
                        {sessionStorage.getItem('disableNav') !== 'true' && (
                          <Button
                            component={Link}
                            disableElevation
                            href={process.env.REACT_APP_LINK_PAYMENT_SETUP}
                            target="_self"
                            sx={{
                              alignSelf: 'flex-end',
                              fontSize: { xs: '14px', md: '16px' },
                              color: `${theme.palette.error['160p']}`,
                              height: { sm: '40px', md: '48px' },
                              padding: '8px 12px',
                              marginBottom: '-8px',
                              marginTop: { xs: '8px', md: 0 },
                              marginLeft: { xs: 0, md: '16px' },
                              ':hover': {
                                backgroundColor: 'transparent',
                                color: 'text.primary',
                              },
                            }}
                          >
                            {t('screens.terminal.paymentsSetup')}
                          </Button>
                        )}
                      </Box>
                    </AlertMessage>
                  )}
                </Box>
                {terminals &&
                  terminals.length > TERMINALS_PER_PAGE &&
                  terminalsCurrentPage.length > 0 && (
                    <>
                      <TablePagination
                        component="div"
                        count={terminals.length}
                        page={page - 1}
                        onPageChange={(event, newPage) => handleChangePage(event, newPage + 1)}
                        rowsPerPage={terminalsPerPage}
                        onRowsPerPageChange={handleChangeTerminalsPerPage}
                        rowsPerPageOptions={[2, 4, 6]}
                        labelRowsPerPage={t('screens.terminal.terminalsPerPage')}
                        labelDisplayedRows={defaultLabelDisplayedTerminals}
                        sx={{
                          '.MuiTablePagination-actions': {
                            display: 'none',
                          },
                        }}
                      />
                      {terminals.length > terminalsPerPage && (
                        <Pagination
                          count={Math.ceil(terminals.length / terminalsPerPage)}
                          page={page}
                          onChange={handleChangePage}
                          color="primary"
                          sx={{ '.MuiPagination-ul': { justifyContent: 'center', marginTop: 1 } }}
                        />
                      )}
                    </>
                  )}
              </Box>
            </Box>
          )}
        </>
      )}
    </Card>
  );
};

export default TerminalStep;
