import React, { useContext } from 'react';
import { Box, Button, Card, Grid, Link, Typography, useTheme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import Image from '../../../img/tutorials/your-store.svg';
import { UserContext } from '../../../context/UserContext';

const YourStore = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { locale } = useContext(UserContext);

  return (
    <Card
      variant="outlined"
      sx={{
        padding: '40px',
      }}
    >
      <Grid container direction="row" alignItems="stretch" spacing={4}>
        <Grid container item direction="row" justifyContent="center" sm="auto">
          <Box
            component="img"
            sx={{
              width: 256.5,
              height: 456,
            }}
            alt="Terminal"
            src={Image}
          />
        </Grid>
        <Grid container item xs={12} sm direction="column" justifyContent="space-between">
          <Box>
            <Typography px={2} pb={1} variant="h2">
              {t('steps.tutorials.yourStore.title')}
            </Typography>
            <Typography variant="body1" px={2}>
              <Trans i18nKey="steps.tutorials.yourStore.paragraph" />
            </Typography>

            <AlertMessage
              isServerAlert
              styles={{ marginTop: 2, padding: { xs: '4px 8px', md: '8px 16px' } }}
              type="warning"
              iconAlignment="baseline"
              locale={locale}
              theme={theme}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 600,
                      lineHeight: 1.5,
                      marginTop: '-2px',
                      marginBottom: '4px',
                    }}
                  >
                    <Trans i18nKey="steps.tutorials.alerts.warningTitle" />
                  </Typography>
                  <Typography variant="body2">
                    <Trans i18nKey="steps.tutorials.yourStore.warningParagraph" />
                  </Typography>
                </Box>
                {sessionStorage.getItem('disableNav') !== 'true' && (
                  <Button
                    component={Link}
                    disableElevation
                    href={process.env.REACT_APP_LINK_PAYMENT_SETUP}
                    target="_self"
                    sx={{
                      alignSelf: 'flex-end',
                      fontSize: { xs: '14px', md: '16px' },
                      color: `${theme.palette.error['160p']}`,
                      height: { sm: '40px', md: '48px' },
                      padding: '8px 12px',
                      marginBottom: '-8px',
                      marginTop: '8px',
                      ':hover': {
                        backgroundColor: 'transparent',
                        color: 'text.primary',
                      },
                    }}
                  >
                    {t('screens.terminal.paymentsSetup')}
                  </Button>
                )}
              </Box>
            </AlertMessage>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default YourStore;
