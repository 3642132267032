import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Error } from '../img/error.svg';

const Fallback = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 6,
        textAlign: 'center',
      }}
    >
      <Error />
      <Typography mt={2} variant="h1" sx={{ fontSize: 24, fontWeight: 'bold' }}>
        {t('components.fallback.somethingWentWrong')}
      </Typography>
      <Typography mt={2} variant="h2" sx={{ fontSize: 20, fontWeight: 'bold' }}>
        {t('components.fallback.fixError')}
      </Typography>
      <Typography mt={2}>{t('components.fallback.refresh')}</Typography>
    </Box>
  );
};

export default Fallback;
