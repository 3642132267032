import { setupWorker } from 'msw';
// import stepsHandlers from './handlers/stepsHandlers';
// import countryHandlers from './handlers/countryHandlers';
// import addressHandlers from './handlers/addressHandlers';
// import kycHandlers from './handlers/kycHandlers';
// import userHandlers from './handlers/userHandlers';
// import themeHandlers from './handlers/themeHandlers';
// import territoryHandlers from './handlers/territoryHandlers';

const handlers = [
  // ...userHandlers,
  // ...stepsHandlers,
  // ...themeHandlers,
  // ...addressHandlers,
  // ...countryHandlers,
  // ...companyInfoHandlers,
  // ...industryTypesHandlers,
  // ...payoutSettingsHandlers,
  // ...terminalsHandlers,
  // ...kycHandlers,
  // ...territoryHandlers,
  // ...appsMenuHandlers
];

const server = setupWorker(...handlers);

export default server;
