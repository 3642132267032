import { Box, Card, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Image from '../../../img/tutorials/testing-2.png';

const TestingPay = () => {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      sx={{
        padding: '40px',
      }}
    >
      <Grid container direction="row" alignItems="stretch" spacing={4}>
        <Grid container item direction="row" justifyContent="center" alignItems="start" sm="auto">
          <Box
            component="img"
            sx={{
              width: 288,
              height: 456,
            }}
            alt="Terminal"
            src={Image}
          />
        </Grid>
        <Grid container item xs={12} sm direction="column" justifyContent="space-between">
          <Box>
            <Typography px={2} pb={1} variant="h2">
              {t('steps.tutorials.testing.title')}
            </Typography>
            <Typography variant="body1" px={2}>
              <Trans i18nKey="steps.tutorials.testing.paragraph-3" />
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              {['steps.tutorials.testing.listItem-3', 'steps.tutorials.testing.listItem-4'].map(
                (str) => (
                  <ListItem key={str} disablePadding sx={{ display: 'list-item' }}>
                    <ListItemText primary={<Trans i18nKey={str} />} />
                  </ListItem>
                )
              )}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TestingPay;
