import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import { UIContext } from '../context/UIContext';
import { multiline } from '../helpers/reactHelpers';
import { UserContext } from '../context/UserContext';

export interface AlertDialogProps {
  open: boolean;
  confirmAction: () => void;
  cancelAction: () => void;
  title: string;
  description: string;
  confirmButtonText: string;
  confirmingButtonText: string;
  isUpdating: boolean;
  banner?: string;
  bannerType?: AlertColor;
  bannerActionText?: string;
  bannerAction?: () => void;
  icon?: ReactElement;
  thirdActionButtonText?: string;
  thirdAction?: () => void;
  textAlign?: 'left' | 'center';
  paperSx?: any;
  actionsSx?: any;
}

const AlertDialog = (props: AlertDialogProps) => {
  const {
    open,
    confirmAction,
    cancelAction,
    title,
    description,
    confirmButtonText,
    confirmingButtonText,
    isUpdating,
    banner,
    bannerType,
    bannerAction,
    bannerActionText,
    icon,
    thirdActionButtonText,
    thirdAction,
    textAlign,
    paperSx,
    actionsSx,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useContext(UIContext);
  const { locale } = useContext(UserContext);
  const alertMessageRef = useRef<HTMLElement>();
  const [alertChanged, setAlertChanged] = useState(false);

  useEffect(() => {
    if (isMobile && alertMessageRef && alertMessageRef.current && alertChanged) {
      alertMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    setAlertChanged(true);
  }, [alertMessageRef, banner, isMobile]);
  useEffect(() => {
    if (!open) setAlertChanged(false);
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={cancelAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={isMobile}
      PaperProps={{
        sx: { overflowY: 'auto', ...paperSx, ...(isMobile ? { padding: '2px' } : {}) },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        component="h3"
        variant="h3"
        sx={textAlign === 'center' ? { textAlign: 'center' } : {}}
      >
        {icon}
        {icon && <div style={{ display: 'block', height: 0, width: 0, marginBottom: '8px' }} />}
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={textAlign === 'center' ? { textAlign: 'center' } : {}}
        >
          {multiline(description)}
        </DialogContentText>
        {banner && (
          <AlertMessage
            action={bannerAction}
            actionBottom={bannerActionText}
            iconAlignment="baseline"
            type={bannerType || 'info'}
            styles={{ marginTop: '24px' }}
            locale={locale}
            theme={theme}
          >
            <span ref={alertMessageRef}>{multiline(banner)}</span>
          </AlertMessage>
        )}
      </DialogContent>
      <DialogActions sx={{ ...actionsSx, ...(isMobile ? { flexDirection: 'column' } : {}) }}>
        {thirdAction && !isMobile && (
          <>
            <Button
              sx={{ textTransform: 'none' }}
              color="primary"
              variant="outlined"
              onClick={thirdAction}
            >
              {thirdActionButtonText}
            </Button>
            <Box flexGrow={1} />
          </>
        )}
        {!isMobile && (
          <Button sx={{ padding: '12px 24px', textTransform: 'none' }} onClick={cancelAction}>
            {t('actions.cancel')}
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          disableElevation
          sx={{
            padding: '12px 24px',
            textTransform: 'none',
            ...(isMobile ? { width: '100%', marginTop: '16px', marginLeft: '8px' } : {}),
          }}
          onClick={() => !isUpdating && confirmAction()}
          autoFocus
        >
          {isUpdating && (
            <>
              <CircularProgress color="inherit" size="20px" sx={{ marginRight: '8px' }} />
              {confirmingButtonText}
            </>
          )}
          {!isUpdating && confirmButtonText}
        </Button>
        {thirdAction && isMobile && (
          <>
            <Button
              sx={{ textTransform: 'none', width: '100%', marginTop: '16px' }}
              color="primary"
              variant="outlined"
              onClick={thirdAction}
            >
              {thirdActionButtonText}
            </Button>
            <Box flexGrow={1} />
          </>
        )}
        {isMobile && (
          <Button
            sx={{ padding: '12px 24px', textTransform: 'none', width: '100%', marginTop: '16px' }}
            onClick={cancelAction}
          >
            {t('actions.cancel')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
