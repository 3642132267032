import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, FormikContextType, FormikProvider } from 'formik';
import { debounce } from 'lodash';
import * as Sentry from '@sentry/react';
import { useMutation } from 'react-query';
import {
  FormikPhoneNumber,
  FormikSelect,
  FormikTextField,
} from '../../../components/FormikComponents';
import { ADDRESS_DRAWER_WIDTH, STATE_LIST } from '../../../constants';
import { UIContext } from '../../../context/UIContext';
import { AddressErrors, PlacesAutocomplete, PlacesDetails } from '../types/addressTypes';
import {
  constructDefaultAddress,
  constructGBAddress,
  constructUSAddress,
  getAddressComponentOfTypeShortName,
} from '../../../helpers/helpers';
import { UserContext } from '../../../context/UserContext';
import { useUserCountry } from '../../../helpers/useCountries';

const AddressForm = ({
  formik,
  cancelAction,
  objectId,
  isSubmitting,
  errors,
  clearError,
}: {
  formik: FormikContextType<any>;
  cancelAction: () => void;
  objectId: string;
  isSubmitting: boolean;
  errors: AddressErrors;
  clearError: (errorKey: string) => void;
}) => {
  const { t } = useTranslation();
  const { isMobile, iOS, colorMode } = useContext(UIContext);
  const { apiFetch } = useContext(UserContext);
  const { userCountry, userCountryName } = useUserCountry();
  const [autoCompletePlaceLocations, setAutoCompletePlaceLocations] = useState<
    PlacesAutocomplete[]
  >([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState<boolean>(false);
  const selectedCountryLabel = formik.values.CountryName;
  const countryToCode: any = {
    'United States': 'US',
    'United Kingdom': 'GB',
    Australia: 'AU',
  };

  useEffect(() => {
    setIsLoadingLocations(false);
    setAutoCompletePlaceLocations([]);
  }, [formik?.values?.Id]);

  const { mutate: fetchAutocompletePlaces } = useMutation(
    (value: { streetAddress: string; countryCode: string }) =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/google/places/autocomplete?${new URLSearchParams(
          {
            input: value.streetAddress,
            country: value.countryCode,
          }
        )}`,
        false,
        {},
        'GET'
      ),
    {
      onSuccess(unFormattedPlaces: any) {
        let places = [];
        if (unFormattedPlaces?.Status === 'OK') {
          places = unFormattedPlaces.Predictions.map((predication: any) => ({
            description: predication.Description,
            placeId: predication.PlaceId,
            ...predication,
          }));
        }

        setAutoCompletePlaceLocations(places);
        setIsLoadingLocations(false);
      },
      onError(error: any) {
        Sentry.captureException(new Error('Error fetching address autocomplete results'), {
          extra: { error: JSON.stringify(error) },
        });
        setIsLoadingLocations(false);
        setAutoCompletePlaceLocations([]);
      },
    }
  );

  const assignAddressDetails = (details: PlacesDetails) => {
    const newDetails = {
      Address1: details?.streetName || formik.values.Address1 || '',
      Town: details?.city || '',
      County: details?.county || '',
      PostCode: details?.postcode || '',
    };

    formik.setValues({
      ...formik.values,
      ...newDetails,
    });

    const savedObjects = JSON.parse(sessionStorage.getItem('addresses') || '{}');
    const currentObject = savedObjects[objectId];

    sessionStorage.setItem(
      'addresses',
      JSON.stringify({
        ...savedObjects,
        [objectId]: { ...currentObject, ...newDetails },
      })
    );
  };

  const { mutate: fetchPlaceDetails } = useMutation(
    (placeId: string) =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/google/places/details?${new URLSearchParams({
          placeId,
        })}`,
        false,
        {},
        'GET'
      ),
    {
      onSuccess(unFormattedPlaceDetails: any) {
        if (unFormattedPlaceDetails?.Status === 'OK') {
          const addressComponents = unFormattedPlaceDetails.Result.AddressComponents;
          const country = getAddressComponentOfTypeShortName(addressComponents, 'country');
          switch (country) {
            case 'GB':
              assignAddressDetails(constructGBAddress(addressComponents));
              return;

            case 'US':
              assignAddressDetails(constructUSAddress(addressComponents));
              return;

            default:
              assignAddressDetails(constructDefaultAddress(addressComponents));
              break;
          }
        }
      },
      onError(error: any) {
        Sentry.captureException(new Error('Error fetching address details'), {
          extra: { error: JSON.stringify(error) },
        });
      },
    }
  );

  const fetchPossibleLocations = useMemo(
    () =>
      debounce(async (streetAddress: string, countryCode: string) => {
        fetchAutocompletePlaces({ streetAddress, countryCode });
      }, 1000),
    []
  );

  const fetchLocationDetails = async (desc: string) => {
    const res = autoCompletePlaceLocations.find(({ description }) => description === desc);

    if (!res) return;

    fetchPlaceDetails(res.placeId);
  };

  const getCountryStatesOptions = (country: string) => {
    const states = STATE_LIST[country];
    if (!states) return null;

    return states.map((state) => ({ value: state, label: state }));
  };

  const countyOptions = getCountryStatesOptions(userCountry);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={{ marginTop: '16px' }}>
        <FormikTextField
          label={t('screens.addresses.addressName')}
          id="Name"
          objectId={objectId}
          autoComplete="organization"
          formName="addresses"
          required
          name="Name"
          error={errors?.Name}
          clearError={clearError}
        />
        <FormikTextField
          label={t('screens.addresses.description')}
          id="Description"
          objectId={objectId}
          formName="addresses"
          name="Description"
          error={errors?.Description}
          clearError={clearError}
        />
        <Typography
          sx={{
            color: (them) => them.palette.text.secondary,
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: 'Open Sans,sans-serif',
            whiteSpace: 'nowrap',
            marginLeft: '14px',
            overflow: 'hidden',
            marginBottom: '8px',
            textOverflow: 'ellipsis',
          }}
        >
          {t('screens.addresses.country')}
        </Typography>
        <Typography sx={{ width: '100%', marginBottom: '26px', marginLeft: '14px' }}>
          {userCountryName}
        </Typography>
        <FormikSelect
          label={t('screens.addresses.address1')}
          id="Address1"
          objectId={objectId}
          autoComplete="address-line1"
          disableFiltering
          freeSolo
          formName="addresses"
          required
          name="Address1"
          error={errors?.Address1}
          clearError={clearError}
          onTextChange={(e: React.FormEvent<HTMLInputElement>) => {
            const fieldText = e && (e.currentTarget || (e.target as HTMLInputElement)).value;
            if (fieldText) {
              setIsLoadingLocations(true);
              fetchPossibleLocations(fieldText, countryToCode[selectedCountryLabel] || userCountry || 'GB');
            } else {
              setIsLoadingLocations(false);
              setAutoCompletePlaceLocations([]);
            }
          }}
          onSelectionChange={async (e: any) => {
            setIsLoadingLocations(false);
            if (e) {
              fetchLocationDetails(e.value);
            }
          }}
          options={autoCompletePlaceLocations.map(({ description }) => ({
            value: description,
            label: description,
          }))}
          isLoading={isLoadingLocations}
          loadingText="screens.addresses.loadingAddresses"
        />
        <FormikTextField
          label={t('screens.addresses.address2')}
          id="Address2"
          objectId={objectId}
          autoComplete="address-line2"
          formName="addresses"
          name="Address2"
          error={errors?.Address2}
          clearError={clearError}
        />
        <FormikTextField
          label={t('screens.addresses.town')}
          id="Town"
          objectId={objectId}
          autoComplete="address-level2"
          formName="addresses"
          required
          name="Town"
          error={errors?.Town}
          clearError={clearError}
        />
        {countyOptions ? (
          <FormikSelect
            label={t('screens.addresses.county')}
            id="County"
            objectId={objectId}
            autoComplete="address-level1"
            formName="addresses"
            name="County"
            required
            error={errors?.County}
            options={countyOptions}
            clearError={clearError}
          />
        ) : (
          <FormikTextField
            label={t('screens.addresses.county')}
            id="County"
            objectId={objectId}
            autoComplete="address-level1"
            formName="addresses"
            name="County"
            error={errors?.County}
            clearError={clearError}
          />
        )}
        <FormikTextField
          label={t('screens.addresses.postCode')}
          id="PostCode"
          objectId={objectId}
          autoComplete="postal-code"
          formName="addresses"
          required
          name="PostCode"
          error={errors?.PostCode}
          clearError={clearError}
        />
        <FormikTextField
          label={t('screens.addresses.emailAddress')}
          id="EmailAddress"
          objectId={objectId}
          autoComplete="email"
          formName="addresses"
          required
          name="EmailAddress"
          error={errors?.EmailAddress}
          clearError={clearError}
        />
        <FormikPhoneNumber
          label={t('screens.addresses.phoneNumber')}
          id="PhoneNumber"
          type="tel"
          objectId={objectId}
          autoComplete="tel"
          formName="addresses"
          required
          name="PhoneNumber"
          error={errors?.PhoneNumber}
          clearError={clearError}
          defaultCountry={userCountry.toLowerCase()}
          countries={[userCountry.toLowerCase()]}
        />
        <Box
          sx={{
            display: 'flex',
            ...(!iOS && {
              width: `calc(${isMobile ? '100%' : `${ADDRESS_DRAWER_WIDTH}px`} - 32px)`,
              position: 'fixed',
              bottom: 0,
              right: 0,
              padding: '8px 16px',
              borderTop: (theme) =>
                `1px ${colorMode === 'light' ? theme.palette.grey['12p'] : theme.palette.grey['23p']
                } solid`,
            }),
            ...(iOS && {
              marginTop: 2,
            }),
            zIndex: 1,
            justifyContent: 'flex-end',
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <Button color="primary" type="button" onClick={cancelAction} sx={{ marginRight: '16px' }}>
            {t('actions.cancel')}
          </Button>
          <Button color="primary" variant="contained" type="submit" disableElevation>
            {isSubmitting && (
              <>
                <CircularProgress color="inherit" size="20px" sx={{ marginRight: '8px' }} />
                {t('actions.saving')}
              </>
            )}
            {!isSubmitting && t('actions.save')}
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};
export default AddressForm;
