import React, { useContext } from 'react';
import {
  Box,
  Button,
  Card,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import Image from '../../../img/tutorials/testing-3.png';
import { UserContext } from '../../../context/UserContext';

const TestingReady = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { locale } = useContext(UserContext);

  return (
    <Card
      variant="outlined"
      sx={{
        padding: '40px',
      }}
    >
      <Grid container direction="row" alignItems="stretch" spacing={4}>
        <Grid container item direction="row" justifyContent="center" alignItems="start" sm="auto">
          <Box
            component="img"
            sx={{
              width: 288,
              height: 456,
            }}
            alt="Terminal"
            src={Image}
          />
        </Grid>
        <Grid container item xs={12} sm direction="column" justifyContent="space-between">
          <Box>
            <Typography px={2} pb={1} variant="h2">
              {t('steps.tutorials.testing.title')}
            </Typography>
            <Typography variant="body1" px={2}>
              <Trans i18nKey="steps.tutorials.testing.paragraph-4" />
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              {['steps.tutorials.testing.listItem-5', 'steps.tutorials.testing.listItem-6'].map(
                (str) => (
                  <ListItem key={str} disablePadding sx={{ display: 'list-item' }}>
                    <ListItemText primary={<Trans i18nKey={str} />} />
                  </ListItem>
                )
              )}
            </List>

            <AlertMessage
              isServerAlert
              styles={{ marginTop: 1, padding: { xs: '4px 8px', md: '8px 16px' } }}
              type="info"
              iconAlignment="baseline"
              locale={locale}
              theme={theme}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 600,
                      lineHeight: 1.5,
                      marginTop: '-2px',
                      marginBottom: '4px',
                    }}
                  >
                    <Trans i18nKey="steps.tutorials.alerts.infoTitle" />
                  </Typography>
                  <Typography variant="body2">
                    <Trans i18nKey="steps.tutorials.testing.infoParagraph-2" />
                  </Typography>
                </Box>
                {sessionStorage.getItem('disableNav') !== 'true' && (
                  <Button
                    component={Link}
                    disableElevation
                    href={process.env.REACT_APP_LINK_TENDER_TYPES}
                    target="_self"
                    sx={{
                      alignSelf: 'flex-end',
                      fontSize: { xs: '14px', md: '16px' },
                      color: `${theme.palette.info['160p']}`,
                      height: { sm: '40px', md: '48px' },
                      padding: '8px 12px',
                      marginBottom: '-8px',
                      marginTop: '8px',
                      ':hover': {
                        backgroundColor: 'transparent',
                        color: 'text.primary',
                      },
                    }}
                  >
                    {t('steps.tutorials.testing.goToActiveTenderTypes')}
                  </Button>
                )}
              </Box>
            </AlertMessage>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TestingReady;
