import React, { useContext } from 'react';
import { useAuth } from 'oidc-react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, useTheme } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { TopBar, TopNav } from '@eposnow/ui-core';
import { UIContext } from '../context/UIContext';
import { UserContext } from '../context/UserContext';
import { deepClone } from '../helpers/helpers';
import MobileTutorialsTopBar from './MobileTutorialsTopBar';

/**
 * Generates a PKCE flow code verifier: A high-entropy cryptographic random STRING using the unreserved
 * characters [A-Z] / [a-z] / [0-9] / "-" / "." / "*" / "~" from Section 2.3 of [RFC3986],
 * with a minimum length of 43 characters and a maximum length of 128 characters.
 * @returns code verifier
 */
const generateVerifier = () => {
  const toCharCodes = (arr: Uint8Array) => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return arr.map((x) => validChars.charCodeAt(x % validChars.length));
  };

  const randomStr = (len: number) => {
    const arr = new Uint8Array(len);
    window.crypto.getRandomValues(arr);
    return String.fromCharCode(...Array.from(toCharCodes(arr)));
  };

  return randomStr(43);
};

const NavigationComponents = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useAuth();
  const { apiFetch, locale, user, appsMenu, cdnUrl, isLoadingUserApps, isErrorUserApps, refetchUserApps } =
    useContext(UserContext);
  const { isMobile, disableAnimation } = useContext(UIContext);

  const logout = () => {
    const idToken = deepClone(auth.userData.id_token);

    apiFetch(`${process.env.REACT_APP_API_SSO}v1/session`, false, undefined, 'DELETE'); // Fail silently

    auth.signOut().then(() => {
      const queryParams = new URLSearchParams({
        id_token_hint: idToken,
        post_logout_redirect_uri: `${process.env.REACT_APP_OAUTH_POST_LOGOUT_REDIRECT_URI}?ReturnUrl=${window.location.origin}/logged-out`,
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        state: generateVerifier(),
      });
      window.location.href = `${process.env.REACT_APP_OAUTH_HOST
        }/oauth2/sessions/logout?${queryParams.toString()}`;
    });
  };

  return !(window.location.pathname?.includes('/tutorials/') && isMobile) ? (
    <TopNav
      openSideNav={false}
      showSideNav={false}
      toggleDrawer={null}
      animationsDisabled={disableAnimation}
      theme={theme}
      isMobile={isMobile}
      user={{
        firstName: user?.FirstName,
        lastName: user?.LastName,
        email: user?.Email,
      }}
      locale={locale}
      currentProject="onboarding"
      logout={logout}
      appsMenuEntries={appsMenu}
      cdnUrl={cdnUrl}
      appVersion={process.env.REACT_APP_BUILD_VERSION}
      accountUrl={
        auth?.userData?.profile?.is_user_migrated
          ? process.env.REACT_APP_LINK_MANAGE_ACCOUNT
          : process.env.REACT_APP_LINK_PROFILE_BACK_OFFICE
      }
      extraIcons={
        !window.location.pathname.includes('tutorials') && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              type="button"
              component={RouterLink}
              to={'/tutorials' as any}
              rel="noopener noreferer"
              aria-label={t('topbar.goToTutorials')}
              title={t('topbar.goToTutorials')}
              sx={{
                color: 'icon.default',
                marginRight: { xs: '-12px', sm: 0 },
                padding: { xs: '12px', sm: '16px' },
              }}
            >
              <MenuBookIcon />
            </IconButton>
          </Box>
        )
      }
      isErrorAppsMenu={isErrorUserApps}
      onRetryAppsMenu={refetchUserApps}
    />
  ) : (
    <TopBar theme={theme}>
      <MobileTutorialsTopBar />
    </TopBar>
  );
};

export default NavigationComponents;
