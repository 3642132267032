import React, { useContext } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  CommonColors,
  PaletteAugmentColorOptions,
  PaletteColor,
  PaletteTonalOffset,
  TypeAction,
  TypeBackground,
  TypeDivider,
} from '@mui/material/styles/createPalette';
import { Color as muiColor, PaletteMode } from '@mui/material';
import { getDesignTokens, setDocumentUiVariables } from '@eposnow/ui-core';
import { UIContext } from './UIContext';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    cardTitle: React.CSSProperties;
    eventTitle: React.CSSProperties;
    textLabel: React.CSSProperties;
    boldText: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    cardTitle?: React.CSSProperties;
    eventTitle?: React.CSSProperties;
    textLabel?: React.CSSProperties;
    boldText?: React.CSSProperties;
  }

  interface SimplePaletteColorOptions {
    light?: string;
    main: string;
    dark?: string;
    contrastText?: string;
    '160p'?: string;
    '190p'?: string;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    contrast?: string;
  }

  export interface Palette {
    common: CommonColors;
    mode: PaletteMode;
    contrastThreshold: number;
    tonalOffset: PaletteTonalOffset;
    primary: PaletteColor;
    secondary: PaletteColor;
    error: PaletteColor;
    warning: PaletteColor;
    info: PaletteColor;
    success: PaletteColor;
    main: muiColor;
    grey: muiColor;
    text: TypeText;
    divider: TypeDivider;
    action: TypeAction;
    background: TypeBackground;
    getContrastText: (background: string) => string;
    augmentColor: (options: PaletteAugmentColorOptions) => PaletteColor;
  }
}

declare module '@mui/material/index' {
  interface Color {
    '4p'?: string;
    '8p'?: string;
    '12p'?: string;
    '16p'?: string;
    '18p'?: string;
    '23p'?: string;
    '26p'?: string;
    '30p'?: string;
    '50p'?: string;
    '54p'?: string;
    '56p'?: string;
    '65p'?: string;
    '70p'?: string;
    '87p'?: string;
    '0dp'?: string;
    '1dp'?: string;
    '2dp'?: string;
    '4dp'?: string;
    '8dp'?: string;
    '12dp'?: string;
    '16dp'?: string;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    cardTitle: true;
    eventTitle: true;
    textLabel: true;
    boldText: true;
  }
}

const ThemeContext = ({ children }: { children: React.ReactNode }) => {
  const { colorMode } = useContext(UIContext);
  const designTokens = getDesignTokens(colorMode ?? 'light');
  setDocumentUiVariables(colorMode ?? 'light');

  return <ThemeProvider theme={createTheme(designTokens)}>{children}</ThemeProvider>;
};

export default ThemeContext;
