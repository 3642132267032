import React from 'react'
import Image from '../../../img/AMS1.png'
import Network from '../../tutorials/steps/Network'
import YourStore from '../../tutorials/steps/YourStore';
import StandAloneApp from '../../tutorials/steps/StandAloneApp';
import TestingAms1 from '../../tutorials/steps/TestingAms1';
import PPComplete from '../../tutorials/steps/Complete';
import { TutorialStepConfig } from '../steps';

const addPath = (stepKey: string) => `/tutorials/ams1-standalone/${stepKey}`

export default {
    header: {
        title: 'Epos Now Payments Standalone',
        subtitle: 'AMS1',
        image: Image
    },
    stepper: [
        {
            stepKey: addPath(`network`),
            stepOrder: 0,
            title: 'steps.tutorials.network.title',
            pointsTo: { next: [addPath(`your-store`)], previous: ''},
            component: <Network />
        },
        {
            stepKey: addPath(`your-store`),
            stepOrder: 1,
            title: 'steps.tutorials.yourStore.title',
            pointsTo: { next: [addPath(`testing`)], previous: addPath(`network`)},
            component: <YourStore />
        },
        {
            stepKey: addPath(`testing`),
            stepOrder: 2,
            title: 'steps.tutorials.testing.title',
            pointsTo: { next: [addPath(`testing-2`)], previous: addPath(`your-store`)},
            component: <StandAloneApp/>
        },
        {
            stepKey: addPath(`testing-2`),
            stepOrder: 3,
            title: 'steps.tutorials.testing.title',
            pointsTo: { next: [addPath(`cellular-2`)], previous: addPath(`testing`)},
            component: <TestingAms1/>
        },
        {
            stepKey: addPath(`cellular-2`),
            stepOrder: 7,
            title: 'steps.tutorials.cellular.title',
            finishStep: true,
            pointsTo: { next: ['/tutorials'], previous: addPath(`testing-2`)},
            component: <PPComplete/>
        }
    ]
} as TutorialStepConfig;