/* eslint-disable import/prefer-default-export */
export interface ErrorObject extends Error {
  errors: unknown;
  code: number;
}

export interface ErrorV4Object extends Error {
  error: string;
  code: number;
}

const defaultError = {
  general: {
    key: 'errorInternalServerErrorEmpty',
    message: 'Internal server error.',
  },
  fields: [],
};

const getError = (code = 500, errors: any = defaultError) => {
  const errorObject: ErrorObject = {
    name: 'Error',
    message: 'Error in API call',
    errors,
    code,
  };

  return errorObject;
};

// USE THE API FETCH FROM USER CONTEXT!!!
export const apiFetchDoNotUse = (
  url: string,
  _v4Errors = true, // @todo Remove this
  body = {},
  method = 'GET',
  authorization: string | undefined = undefined,
  retry = true,
  plain = false,
  extraHeaders: Record<string, string> = {}
) => {
  let options: { method: string; body?: string } = {
    method,
  };
  if (method !== 'GET' && method !== 'DELETE') {
    options = { ...options, body: JSON.stringify(body) };
  }

  const headers: Record<string, string> = {
    ...extraHeaders,
    'Content-Type': 'application/json',
  };

  if (authorization) headers.Authorization = authorization;

  return fetch(url, {
    ...options,
    headers,
  }).then((response): Promise<any> => {
    if (response.ok && plain) {
      return response.text();
    }
    if (response.ok)
      return response
        .json()
        .catch(() => response)
        .then((responseData) => responseData && responseData.data && (Object.keys(responseData).length === 1) ? responseData.data : responseData);

    const validateUrl = `${process.env.REACT_APP_API_SSO}v1/token/validate`;

    if (response.status === 401) {
      if (url === validateUrl) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      } else if (retry){
        apiFetchDoNotUse(validateUrl, false, undefined, undefined, authorization, false);
      }
    }

    return response
      .json()
      .catch(() => {
        throw getError(response.status);
      })
      .then((error) => {
        throw getError(error.code || response.status, error.errors || error);
      });
  });
};
