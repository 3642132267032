import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';
import { AlertMessage, SnackBarMessage } from '@eposnow/ui-core';
import { PayoutSettingsType } from '../../types';
import {
  FormikSimpleSelect,
  payoutSettingsValidationSchema,
} from '../../components/FormikComponents';
import ErrorLoadingData from '../../components/ErrorLoadingData';
import { useSteps } from '../../components/StepController';
import TransferOption from '../verification/TransferOption';
import { ReactComponent as TimeScheduleIcon } from '../../img/icons/time-schedule.svg';
import { ReactComponent as TimeScheduleIconWhite } from '../../img/icons/time-schedule-white.svg';
import { UserContext } from '../../context/UserContext';
import { UIContext } from '../../context/UIContext';

const PayoutSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userData } = useAuth();
  const { apiFetch, locale } = useContext(UserContext);
  const { colorMode, isMobile } = useContext(UIContext);
  const queryClient = useQueryClient();
  const stepContext = useSteps();

  const [payoutSettings, setPayoutSettings] = useState<PayoutSettingsType>({
    PayoutTime: '',
    UtcOffset: 0,
    PayoutDelayDays: 1,
    Timezone: null,
  });
  const [previousSettings, setPreviousSettings] = useState(payoutSettings);
  const [loadedStoredInfo, setLoadedStoredInfo] = useState(false);
  const [successMessageUpdatePayoutSettings, setSuccessMessageUpdatePayoutSettings] =
    useState<string>('');
  const [errorMessageUpdatePayoutSettings, setErrorMessageUpdatePayoutSettings] =
    useState<string>('');
  const [errorMessageTransferOptionRequired, setErrorMessageTransferOptionRequired] =
    useState<string>('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  const messageRef = useRef<null | HTMLElement>(null);

  const transferOptions = [
    {
      payoutDelayDays: 1,
      type: 'standard',
    },
    {
      payoutDelayDays: 0,
      type: 'instant',
    },
  ];

  // Please don't "fix" this by adding 23:00 or 00:00.
  // That entry is kept out because Adyen considers 00:00 really as sort of "23:59" of that day and not 00:00. 23:00 is removed as it causes issues with Adyen with midnight CEST
  const payoutOptions = Array.from(Array(22).keys()).map(
    (it) => `${(it + 1).toString().padStart(2, '0')}:00`
  );

  const {
    refetch: fetchData,
    data: savedPayoutSettings,
    isLoading,
    isError,
    error,
  } = useQuery<PayoutSettingsType>(
    ['payoutSettings'],
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_PAYMENTS}v1/payments/payout-settings/get-payout-settings`
      ),
    {
      staleTime: 0,
    }
  );

  useEffect(() => {
    setPayoutSettings(savedPayoutSettings);
    setPreviousSettings(savedPayoutSettings);
  }, [savedPayoutSettings]);

  const onSuccess = useCallback(async () => {
    await queryClient.invalidateQueries(['payoutSettings']);
    setErrorMessageUpdatePayoutSettings('');
    setSuccessMessageUpdatePayoutSettings(t('screens.verification.successUpdatePayoutSettings'));
    setPreviousSettings(payoutSettings);
    setHasUnsavedChanges(false);
  }, [queryClient.invalidateQueries, payoutSettings]);

  const onError = useCallback(
    (err: unknown) => {
      if ((err as any)?.code === 401) return;
      Sentry.captureException(new Error('Error updating payout settings'), {
        extra: { error: JSON.stringify(err) },
      });
      setErrorMessageUpdatePayoutSettings(t('errors.errorUpdatingPayoutSettings'));
      setPayoutSettings(previousSettings);
      setHasUnsavedChanges(false);
    },
    [previousSettings]
  );

  const { isLoading: isUpdatingPayoutSettings, mutate: updatePayoutSettings } = useMutation(
    (data: PayoutSettingsType) =>
      apiFetch(
        `${process.env.REACT_APP_API_PAYMENTS}v1/payments/payout-settings/update-payout-settings`,
        true,
        data,
        'POST'
      ),
    {
      onSuccess,
      onError,
    }
  );

  const formik = useFormik({
    initialValues: {
      PayoutTime: payoutSettings?.PayoutTime || '22:00',
    },
    validationSchema: payoutSettingsValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (isUpdatingPayoutSettings) return;

      if (!payoutSettings.PayoutDelayDays && payoutSettings.PayoutDelayDays !== 0) {
        setErrorMessageTransferOptionRequired(t('validation.required.payoutPlan'));
        return;
      }

      actions.setSubmitting(false);
      const newSettings = {
        ...payoutSettings,
        PayoutTime: values.PayoutTime,
        UtcOffset: 0,
        Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      setPayoutSettings(newSettings);
      updatePayoutSettings(newSettings);
    },
  });

  useEffect(() => {
    if (!payoutSettings || !stepContext?.updateCurrentStep) return;
    stepContext.updateCurrentStep({
      error: '',
      completable:
        payoutSettings &&
        payoutSettings.PayoutTime != null &&
        payoutSettings.PayoutTime !== '' &&
        payoutSettings?.PayoutDelayDays != null &&
        payoutSettings?.PayoutDelayDays > -1 &&
        !isUpdatingPayoutSettings,
    });
    formik.setValues({ PayoutTime: payoutSettings.PayoutTime });
  }, [payoutSettings, isUpdatingPayoutSettings]);

  const checkStoredDataHasChanges = (newData: PayoutSettingsType) =>
    ((newData.PayoutDelayDays || newData.PayoutDelayDays === 0) &&
      newData.PayoutDelayDays !== savedPayoutSettings?.PayoutDelayDays) ||
    (newData.PayoutTime && newData.PayoutTime !== savedPayoutSettings?.PayoutTime) ||
    (!newData.PayoutTime && !!savedPayoutSettings?.PayoutTime);

  useEffect(() => {
    if (!savedPayoutSettings) return;
    setHasUnsavedChanges(false);
    setPayoutSettings(savedPayoutSettings);
    formik.setValues({
      PayoutTime: savedPayoutSettings.PayoutTime || '22:00',
    });
    setLoadedStoredInfo(true);
  }, [savedPayoutSettings]);

  const changeTransferOption = (payoutDelayDays: number) => {
    setPayoutSettings({
      ...payoutSettings,
      PayoutDelayDays: payoutDelayDays,
    });
  };

  const updateStepError = () => {
    if (stepContext?.currentStep?.error && stepContext?.updateCurrentStep)
      stepContext.updateCurrentStep({
        error: t(`errors.errorNeedsToSave`),
      });
  };

  useEffect(() => {
    if (!savedPayoutSettings || !loadedStoredInfo) return;

    if (formik?.values?.PayoutTime || payoutSettings?.PayoutDelayDays) updateStepError();

    setHasUnsavedChanges(
      checkStoredDataHasChanges({
        ...payoutSettings,
        PayoutTime: formik?.values?.PayoutTime,
      })
    );
  }, [formik?.values?.PayoutTime, payoutSettings?.PayoutDelayDays]);

  useEffect(() => {
    if (hasUnsavedChanges) {
      formik.submitForm();
    }
  }, [hasUnsavedChanges]);

  const unauthorized = (error as any)?.code === 401;

  const timeScheduleIcon = colorMode === 'light' ? <TimeScheduleIcon /> : <TimeScheduleIconWhite />;

  return (
    <FormikProvider value={formik}>
      {(successMessageUpdatePayoutSettings ||
        errorMessageUpdatePayoutSettings ||
        isUpdatingPayoutSettings) && (
          <Box marginBottom={2}>
            <SnackBarMessage
              message={
                isUpdatingPayoutSettings
                  ? t('actions.pleaseWait')
                  : successMessageUpdatePayoutSettings
              }
              setMessage={setSuccessMessageUpdatePayoutSettings}
              isMobile={isMobile}
              locale={locale}
              theme={theme}
            />
            {errorMessageUpdatePayoutSettings && (
              <AlertMessage
                type="error"
                alertRef={messageRef}
                action={() => setErrorMessageUpdatePayoutSettings('')}
                iconAlignment="baseline"
                locale={locale}
                theme={theme}
              >
                {errorMessageUpdatePayoutSettings}
              </AlertMessage>
            )}
          </Box>
        )}
      <Form onSubmit={formik.handleSubmit}>
        <Card
          variant="outlined"
          sx={{
            marginBottom: 2,
            paddingBottom: 0,
          }}
        >
          {isLoading || (!loadedStoredInfo && !isError) || unauthorized ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '96px',
                p: { xs: 2, sm: 3 },
              }}
            >
              <CircularProgress
                aria-label="Loading Data"
                aria-live="polite"
                aria-busy={isLoading || unauthorized}
                data-testid="loading-icon"
                sx={{ color: 'text.secondary' }}
              />
            </Box>
          ) : (
            <>
              {isError && <ErrorLoadingData action={fetchData} />}
              {!isError && (
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    p: { xs: 2, sm: 3 },
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'left',
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        alignSelf: 'flex-start',
                      }}
                    >
                      <Typography variant="h2" mb={1}>
                        {t('screens.verification.payoutPlan')}
                      </Typography>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {t('screens.verification.planDescription')}
                      </Typography>
                    </Box>
                    {timeScheduleIcon}
                  </Box>
                  <FormControl
                    error={errorMessageTransferOptionRequired !== ''}
                    sx={{ marginTop: '16px' }}
                  >
                    <Grid container spacing={{ xs: 1, sm: 3 }}>
                      {transferOptions.map((transfer) => {
                        const isSelected =
                          payoutSettings !== undefined &&
                          transfer.payoutDelayDays === payoutSettings.PayoutDelayDays;
                        return (
                          <TransferOption
                            transfer={transfer}
                            isSelected={isSelected}
                            changeTransferOption={changeTransferOption}
                            key={`transfer-${transfer.type}`}
                          />
                        );
                      })}
                    </Grid>

                    {payoutSettings && payoutSettings.PayoutDelayDays === 0 && (
                      <Alert
                        variant="filled"
                        severity="info"
                        role="alert"
                        sx={{
                          scrollMargin: '60px',
                          flexGrow: 1,
                          fontWeight: 400,
                          display: 'flex',
                          alignItems: 'left',
                          marginTop: '18px',
                          paddingLeft: '18px',
                          paddingRight: '18px',
                          color: theme.palette.info['160p'],
                          backgroundColor: theme.palette.info['190p'],
                          '.MuiAlert-message': {
                            width: '100%',
                          },
                          '.MuiAlert-action': {
                            paddingTop: 0,
                          },
                          '.MuiAlert-icon': {
                            marginTop: '2px',
                            alignSelf: 'baseline',
                            display: isMobile ? 'none' : undefined,
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Typography component="span">
                            {t('screens.verification.instantTransferInfo')}
                          </Typography>
                          <Button
                            component="a"
                            href={process.env.REACT_APP_LINK_FASTER_PAYMENTS_INFO}
                            target="_blank"
                            variant="text"
                            sx={{
                              alignSelf: 'flex-end',
                              padding: '8px',
                              color: theme.palette.info['160p'],
                            }}
                          >
                            {t('actions.learnMore')}
                          </Button>
                        </Box>
                      </Alert>
                    )}
                  </FormControl>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '32px',
                      width: '100%',
                      flexWrap: 'wrap',
                      gap: '12px',
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        alignSelf: 'flex-start',
                      }}
                    >
                      <Typography variant="h2" mb={1}>
                        {t('screens.verification.payoutSchedule')}
                      </Typography>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {t('screens.verification.scheduleDescription')}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        marginTop: isMobile ? '18px' : 0,
                        alignSelf: 'center',
                        padding: 0,
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    >
                      <FormikSimpleSelect
                        name="PayoutTime"
                        label={t('screens.verification.payoutTime')}
                        id="PayoutTime"
                        required
                        options={payoutOptions}
                        objectId={userData.profile.sub}
                        formName="payoutSettings"
                        smallPadding
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default PayoutSettings;
