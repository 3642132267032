import * as React from 'react';
import { useContext } from 'react';
import { Drawer, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { WithScrollbars } from '@eposnow/ui-core';
import Markdown from '../../../components/Markdown';
import DrawerTitlebar from '../../../components/DrawerTitlebar';
import { UIContext } from '../../../context/UIContext';

export interface HelpPanelProps {
  title: string;
  children: string;
  images?: Record<string, string>;
  onClose: () => void;
}

const HelpPanel = (props: HelpPanelProps) => {
  const { title, children, images, onClose } = props;
  const { isMobile } = useContext(UIContext);

  return (
    <Drawer
      ModalProps={{ keepMounted: false }}
      anchor="right"
      open
      variant={isMobile ? 'temporary' : 'permanent'}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '320px',
          overflow: 'hidden',
          height: isMobile ? '100%' : 'calc(100% - 65px)',
          boxShadow:
            '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
          clipPath: 'inset(0px 0px 0px -74px)',
          marginTop: isMobile ? 0 : '65px',
        },
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <DrawerTitlebar title={title} onClose={onClose} icon={<InfoIcon />} />
        </Grid>
        <Grid item>
          <WithScrollbars
            style={{
              height: isMobile ? 'calc(100vh - 89px)' : 'calc(100vh - 154px)',
              padding: '16px',
            }}
          >
            <Markdown
              images={images}
              css={{
                infoLink: { fontSize: '16px' },
                info: { marginBottom: '16px' },
              }}
            >
              {children}
            </Markdown>
          </WithScrollbars>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default HelpPanel;
