import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  TablePagination,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { SnackBarMessage, SideBar } from '@eposnow/ui-core';
import { slugify } from '../../helpers/helpers';
import useCountries from '../../helpers/useCountries';
import CardButton from '../../components/CardButton';
import ErrorLoadingData from '../../components/ErrorLoadingData';
import ServerAlertMessage from '../../components/ServerAlertMessage';
import { Address, AddressErrors } from './types/addressTypes';
import CreateEditAddressCard from './components/CreateEditAddressCard';
import InfoAddressCard from './components/InfoAddressCard';
import {
  ADDRESS_DRAWER_WIDTH,
  ADDRESSES_PER_LARGE_PAGE,
  ADDRESSES_PER_PAGE,
  LG_WIDTH,
  NAV_DRAWER_WIDTH,
} from '../../constants';
import { UIContext } from '../../context/UIContext';
import { useSteps } from '../../components/StepController';
import { UserContext } from '../../context/UserContext';
import GoogleOAuth from '../../components/GoogleOAuth';

const Addresses = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { apiFetch, locale } = useContext(UserContext);

  document.title = t('app.pageTitle', { name: t('nav.locations') });
  const [addressesCurrentPage, setAddressesCurrentPage] = useState<Address[]>([]);
  const {
    countries,
    fetchData: fetchCountries,
    isLoading: isLoadingCountries,
    showAlertMessage,
  } = useCountries();
  const stepContext = useSteps();
  const { isMobile, isLargeDevice, disableAnimation, iOS } = useContext(UIContext);
  const [isSlicingAddresses, setIsSlicingAddresses] = useState(true);
  const [isCreatingAddress, setIsCreatingAddress] = useState(false);
  const [createdNewAddress, setCreatedNewAddress] = useState(false);
  const [addressBeingEdited, setAddressBeingEdited] = useState<Address | undefined>(undefined);
  const [successAddressMessage, setSuccessAddressMessage] = useState<string>('');
  const [errorAddressMessage, setErrorAddressMessage] = useState<string>('');
  const [errors, setErrors] = useState<AddressErrors>({});
  const [page, setPage] = useState(1);
  const [addressesPerPage, setAddressesPerPage] = React.useState(
    isLargeDevice ? ADDRESSES_PER_LARGE_PAGE : ADDRESSES_PER_PAGE
  );
  const navigate = useNavigate();
  const { addressId } = useParams();

  const messageRef = useRef<null | HTMLElement>(null);

  const handleChangePage = (event: ChangeEvent<unknown> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeAddressesPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAddressesPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const dimensionsTheme = useTheme();
  const isLargeDeviceSwipeable = useMediaQuery(
    dimensionsTheme.breakpoints.up(LG_WIDTH + NAV_DRAWER_WIDTH + ADDRESS_DRAWER_WIDTH)
  );

  const resetCurrentAddress = () => {
    if (isCreatingAddress) {
      setIsCreatingAddress(false);
    } else {
      setAddressBeingEdited(undefined);
    }
    setErrorAddressMessage('');
    setErrors({});
  };

  const resetAndNavigate = () => {
    resetCurrentAddress();
    navigate('');
  };

  useEffect(() => {
    if (showAlertMessage && messageRef.current)
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [showAlertMessage]);

  const {
    refetch: fetchAddresses,
    data: addresses,
    isLoading,
    isError,
    error,
  } = useQuery<Address[]>(
    ['addresses'],
    () => apiFetch(`${process.env.REACT_APP_API_V4}location`),
    {
      staleTime: 0,
      onSuccess(data: Address[]) {
        if (stepContext?.updateCurrentStep)
          stepContext.updateCurrentStep({
            error: '',
            completable: data?.length > 0,
          });
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error fetching addresses'), {
          extra: { error: JSON.stringify(err) },
        });
        setIsSlicingAddresses(false);
        if (stepContext?.updateCurrentStep) stepContext.updateCurrentStep({ error: '' });
      },
    }
  );

  const unauthorized = (error as any)?.code === 401;

  useEffect(() => {
    if (addresses && stepContext?.updateCurrentStep)
      stepContext.updateCurrentStep({
        error: '',
        completable: addresses.length > 0,
      });
  }, [addresses]);

  useEffect(() => {
    if (!addresses) return;
    if (Array.isArray(addresses)) {
      let newPage = page;
      if (createdNewAddress) {
        newPage = Math.ceil(addresses.length / addressesPerPage);
        setPage(newPage);
      }
      setAddressesCurrentPage(
        addresses.slice(
          (newPage - 1) * addressesPerPage,
          (newPage - 1) * addressesPerPage + addressesPerPage
        )
      );
      setCreatedNewAddress(false);
    }

    setIsSlicingAddresses(false);
  }, [page, addressesPerPage, addresses]);

  useEffect(() => {
    if (addressId && addresses) {
      resetCurrentAddress();
      if (addressId === '0') {
        setIsCreatingAddress(true);
      } else {
        const address = addresses?.find((elementAddress) => `${elementAddress.Id}` === addressId);
        if (address) {
          setAddressBeingEdited(address);
        }
      }
    } else if (isCreatingAddress || addressBeingEdited) {
      resetCurrentAddress();
    }
  }, [addressId, addresses]);

  const defaultLabelDisplayedAddresses = ({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) => t('screens.addresses.fromToAddresses', { from, to, count });

  return (
    <div>
      {isLoading || unauthorized || isSlicingAddresses ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '96px',
          }}
        >
          <CircularProgress
            aria-label="Loading Data"
            aria-live="polite"
            aria-busy={isLoading || unauthorized || isSlicingAddresses}
            data-testid="loading-icon"
            sx={{ color: 'text.secondary' }}
          />
        </Box>
      ) : (
        <>
          {showAlertMessage && (
            <ServerAlertMessage
              message={t(`errors.errorLoadingCountries`)}
              isLoading={isLoadingCountries}
              refetch={fetchCountries}
              alertRef={messageRef}
            />
          )}
          <SnackBarMessage
            message={successAddressMessage}
            setMessage={setSuccessAddressMessage}
            isMobile={isMobile}
            locale={locale}
            theme={theme}
          />
          {isError && <ErrorLoadingData action={fetchAddresses} />}
          {!isError && (
            <>
              <Grid container spacing={{ xs: 1, sm: 3 }}>
                <Grid item xs={12} sm={6} lg={4}>
                  <CardButton
                    icon={<AddOutlinedIcon />}
                    action={() => addressId !== '0' && navigate('0')}
                    styles={{
                      ...(!isMobile &&
                        addresses.length === 0 && {
                          height: '280px',
                        }),
                    }}
                  >
                    <Typography variant="h2">{t('screens.addresses.addNewAddress')}</Typography>
                  </CardButton>
                </Grid>
                {addressesCurrentPage?.map((address: Address) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    key={`address-${slugify(`${address.Id}`)}`}
                    sx={{ display: 'flex' }}
                  >
                    <InfoAddressCard
                      countries={countries}
                      address={address}
                      clickEditButton={() => {
                        if (addressId !== `${address.Id}`) navigate(`${address.Id}`);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              {addresses && addressesCurrentPage.length > 0 && (
                <>
                  <TablePagination
                    component="div"
                    count={addresses.length}
                    page={page - 1}
                    onPageChange={(event, newPage) => handleChangePage(event, newPage + 1)}
                    rowsPerPage={addressesPerPage}
                    onRowsPerPageChange={handleChangeAddressesPerPage}
                    rowsPerPageOptions={[3, 5, 7]}
                    labelRowsPerPage={t('screens.addresses.addressesPerPage')}
                    labelDisplayedRows={defaultLabelDisplayedAddresses}
                    sx={{
                      '.MuiTablePagination-actions': {
                        display: 'none',
                      },
                    }}
                  />
                  {addresses.length > addressesPerPage && (
                    <Pagination
                      count={Math.ceil(addresses.length / addressesPerPage)}
                      page={page}
                      onChange={handleChangePage}
                      color="primary"
                      sx={{ '.MuiPagination-ul': { justifyContent: 'center', marginTop: 1 } }}
                    />
                  )}
                </>
              )}
            </>
          )}
          {process.env.REACT_APP_ENABLE_MARKETING === 'true' && (
            <Box sx={{ ...(isMobile && { paddingX: 2, paddingBottom: 8 }) }}>
              <GoogleOAuth onImportCallback={fetchAddresses} />
            </Box>
          )}
          {(isCreatingAddress || addressBeingEdited) && (
            <SideBar
              open={isCreatingAddress || addressBeingEdited != null}
              toggleDrawer={resetAndNavigate}
              showSwipeableDrawer={!isMobile && !isLargeDeviceSwipeable}
              width={{
                drawerWidth: !isMobile ? ADDRESS_DRAWER_WIDTH : undefined,
                closedDrawerWidth: 0,
              }}
              anchor="right"
              belowTopBar={!isMobile}
              disableSwipe={!isMobile}
              iOS={iOS}
              animationsDisabled={disableAnimation}
              theme={theme}
            >
              <>
                {!isMobile && <Toolbar />}
                <CreateEditAddressCard
                  currentAddress={isCreatingAddress || addressBeingEdited}
                  resetAddress={resetAndNavigate}
                  setCreatedNewAddress={setCreatedNewAddress}
                  setSuccessAddressMessage={setSuccessAddressMessage}
                  errorAddressMessage={errorAddressMessage}
                  setErrorAddressMessage={setErrorAddressMessage}
                  errors={errors}
                  setErrors={setErrors}
                  mode={isCreatingAddress ? 'create' : 'edit'}
                />
              </>
            </SideBar>
          )}
        </>
      )}
    </div>
  );
};

export default Addresses;
