import { Box, Button, Card, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useActions } from '../../../components/TutorialStepController';
import { UIContext } from '../../../context/UIContext';
import Image from '../../../img/tutorials/network-8-1.png';
import Image2 from '../../../img/tutorials/network-8-2.png';

const NetworkConnectionOption = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(UIContext);

  const actionContext = useActions();

  return (
    <Card
      variant="outlined"
      sx={{
        padding: '40px',
      }}
    >
      <Grid container direction="row" alignItems="stretch" spacing={4}>
        <Grid container item direction="row" justifyContent="center" sm="auto">
          <Box
            component="img"
            mr={isMobile ? 0 : 2}
            mb={isMobile ? 2 : 0}
            sx={{
              width: 256.5,
              height: 220,
            }}
            alt="Terminal"
            src={Image}
          />
          <Box
            component="img"
            sx={{
              width: 256.5,
              height: 220,
            }}
            alt="Terminal"
            src={Image2}
          />
        </Grid>
        <Grid container item xs={12} sm direction="column" justifyContent="space-between">
          <Box>
            <Typography px={2} pb={1} variant="h2">
              {t('steps.tutorials.network.title')}
            </Typography>
            <Typography variant="body1" px={2}>
              {t('steps.tutorials.network.paragraph-3')}
            </Typography>
          </Box>

          <Box
            sx={{
              paddingX: { xs: 0, lg: 3 },
              paddingTop: isMobile ? 3 : 0,
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-end',
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              disableElevation
              sx={{
                paddingY: { xs: '6px', sm: '10px' },
                paddingX: { xs: 2, sm: 3 },
                mr: 2,
              }}
              onClick={() => actionContext.handleNext(1)}
            >
              {t('steps.tutorials.network.ethernet')}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              disableElevation
              sx={{
                paddingY: { xs: '6px', sm: '10px' },
                paddingX: { xs: 2, sm: 3 },
              }}
              onClick={() => actionContext.handleNext(0)}
            >
              {t('steps.tutorials.network.wifi')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NetworkConnectionOption;
