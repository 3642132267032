import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Box, Typography, CircularProgress, Button, Grid, useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';
import { AlertMessage, SnackBarMessage } from '@eposnow/ui-core';
import {
  FormikTextField,
  companyInfoValidationSchema,
  FormikDatePicker,
  FormikSelect,
} from '../../components/FormikComponents';
import { CompanyInfo, CompanyInfoForm } from '../../types';
import ServerAlertMessage from '../../components/ServerAlertMessage';
import ErrorLoadingData from '../../components/ErrorLoadingData';
import { useSteps } from '../../components/StepController';
import useIndustryTypes from '../../helpers/useIndustryTypes';
import { datesAreTheSame, dateTodayOrAfter, formatDates } from '../../helpers/helpers';
import { UserContext } from '../../context/UserContext';
import { UIContext } from '../../context/UIContext';

const emptyCompanyInfo = {
  organisationId: '',
  organisationName: '',
  industryType: null,
  startTradingDate: '',
};

const BusinessInfo = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  document.title = t('app.pageTitle', { name: t('nav.business-info') });
  const queryClient = useQueryClient();
  const { userData } = useAuth();
  const { apiFetch, locale } = useContext(UserContext);
  const { isMobile } = useContext(UIContext);
  const stepContext = useSteps();
  const [successMessageUpdateCompanyInfo, setSuccessMessageUpdateCompanyInfo] =
    useState<string>('');
  const [errorMessageUpdateCompanyInfo, setErrorMessageUpdateCompanyInfo] = useState<string>('');
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>(emptyCompanyInfo);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const messageRef = useRef<null | HTMLElement>(null);

  const {
    industryTypes,
    fetchData: fetchIndustryTypes,
    isLoading: isLoadingIndustryTypes,
    showAlertMessage: showAlertMessageIndustryTypes,
  } = useIndustryTypes();

  const {
    refetch: fetchCompanyInfo,
    data: savedCompanyInfo,
    isLoading,
    isError,
    error: companyInfoError,
  } = useQuery<CompanyInfo>(
    ['companyInfo'],
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/info`,
        false
      ),
    {
      staleTime: 0,
      onSuccess(data: CompanyInfo) {
        if (stepContext?.updateCurrentStep)
          stepContext.updateCurrentStep({
            error: '',
            completable:
              data.organisationName != null &&
              data.organisationName !== '' &&
              data.industryType != null &&
              data.startTradingDate != null &&
              data.startTradingDate !== '',
          });
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error fetching company info'), {
          extra: { error: JSON.stringify(err) },
        });
        if (stepContext?.updateCurrentStep) stepContext.updateCurrentStep({ error: '' });
      },
    }
  );

  useEffect(() => {
    if ((errorMessageUpdateCompanyInfo || showAlertMessageIndustryTypes) && messageRef.current)
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [errorMessageUpdateCompanyInfo, showAlertMessageIndustryTypes]);

  const { isLoading: isUpdatingCompanyInfo, mutate: updateCompanyInfo } = useMutation(
    (data: CompanyInfo) =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/info`,
        false,
        data,
        'PUT'
      ),
    {
      async onSuccess() {
        sessionStorage.removeItem('companyInfo');
        await queryClient.invalidateQueries(['companyInfo']);
        setErrorMessageUpdateCompanyInfo('');
        setSuccessMessageUpdateCompanyInfo(t('screens.businessInfo.successEditBusinessInfo'));
        setHasUnsavedChanges(false);
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error updating company info'), {
          extra: { error: JSON.stringify(err) },
        });
        setErrorMessageUpdateCompanyInfo(t('errors.errorUpdatingBusinessInfo'));
      },
    }
  );

  const unauthorized = (companyInfoError as any)?.code === 401;

  const formik = useFormik({
    initialValues: {
      ...companyInfo,
      organisationName: companyInfo?.organisationName || '',
      industryType: companyInfo?.industryType?.toString() || '',
      startTradingDate: companyInfo?.startTradingDate || '',
    },
    validationSchema: companyInfoValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const date = new Date(values.startTradingDate);

      if (isUpdatingCompanyInfo || values.startTradingDate === 'Invalid Date') return;

      if (values.startTradingDate && !dateTodayOrAfter(formatDates(date, new Date()))) {
        formik.setValues({
          ...formik.values,
          startTradingDate: '',
        });
        return;
      }

      actions.setSubmitting(false);
      const newValues = companyInfoValidationSchema.cast(values) as CompanyInfoForm;
      const newIndustryType = industryTypes.find(
        (type) =>
          type.value === newValues.industryType ||
          type.label.toLowerCase() === newValues.industryType?.toLowerCase()
      );
      if (!newIndustryType) {
        formik.setValues({
          ...formik.values,
          industryType: '',
        });
        return;
      }
      const tradingDate = newValues.startTradingDate
        ? new Date(`${date.toDateString()} GMT`).toJSON()
        : null;
      updateCompanyInfo({
        ...newValues,
        startTradingDate: tradingDate,
        industryType: parseInt(newIndustryType.value.toString(), 10),
      });
    },
  });

  const checkDateHasChanged = (date1: string | null, date2: string) =>
    (date1 && date2 && !datesAreTheSame(formatDates(new Date(date1), new Date(date2)))) ||
    (!date1 && !!date2) ||
    (!!date1 && !date2);

  const checkStoredDataHasChanges = (newData: CompanyInfoForm) =>
    (newData.organisationName && newData.organisationName !== savedCompanyInfo?.organisationName) ||
    (!newData.organisationName && !!savedCompanyInfo?.organisationName) ||
    (newData.industryType &&
      newData.industryType.toString() !== savedCompanyInfo?.industryType?.toString()) ||
    (!newData.industryType && !!savedCompanyInfo?.industryType?.toString()) ||
    checkDateHasChanged(newData.startTradingDate, savedCompanyInfo?.startTradingDate || '');

  useEffect(() => {
    setHasUnsavedChanges(false);

    if (!savedCompanyInfo) return;

    setCompanyInfo(savedCompanyInfo);
    formik.setValues({
      ...savedCompanyInfo,
      organisationName: savedCompanyInfo.organisationName || '',
      industryType: savedCompanyInfo.industryType?.toString() || '',
      startTradingDate: savedCompanyInfo.startTradingDate || '',
    });

    if (stepContext?.updateCurrentStep)
      stepContext.updateCurrentStep({
        error: '',
        completable:
          savedCompanyInfo?.organisationName != null &&
          savedCompanyInfo?.organisationName !== '' &&
          savedCompanyInfo?.industryType != null &&
          savedCompanyInfo?.startTradingDate != null &&
          savedCompanyInfo?.startTradingDate !== '',
      });
  }, [savedCompanyInfo]);

  const cancelUpdate = () => {
    if (!savedCompanyInfo) return;

    if (stepContext?.updateCurrentStep)
      stepContext.updateCurrentStep({
        error: t(''),
      });
    setHasUnsavedChanges(false);
    setCompanyInfo(savedCompanyInfo);
    sessionStorage.removeItem('companyInfo');
    formik.setValues({
      ...savedCompanyInfo,
      organisationName: savedCompanyInfo.organisationName || '',
      industryType: savedCompanyInfo.industryType?.toString() || '',
      startTradingDate: savedCompanyInfo.startTradingDate || '',
    });
  };

  const updateStepError = () => {
    if (stepContext?.currentStep?.error && stepContext?.updateCurrentStep)
      stepContext.updateCurrentStep({
        error: t(`errors.errorNeedsToSave`),
      });
  };

  useEffect(() => {
    if (!savedCompanyInfo || !companyInfo.organisationId) return;

    if (
      formik.values.organisationName ||
      formik.values.industryType ||
      formik.values.startTradingDate
    )
      updateStepError();
    setHasUnsavedChanges(checkStoredDataHasChanges(formik.values));
  }, [
    formik?.values?.organisationName,
    formik?.values?.industryType,
    formik?.values?.startTradingDate,
  ]);

  return (
    <Card variant="outlined">
      <Box
        sx={{
          padding: { xs: 2, sm: 3 },
          paddingBottom: { xs: 0, sm: 0 },
        }}
      >
        <Typography variant="h2">{t('screens.businessInfo.title')}</Typography>
      </Box>
      {isLoading || unauthorized ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '96px',
          }}
        >
          <CircularProgress
            aria-label="Loading Data"
            aria-live="polite"
            aria-busy={isLoading || unauthorized}
            data-testid="loading-icon"
            sx={{ color: 'text.secondary' }}
          />
        </Box>
      ) : (
        <>
          <SnackBarMessage
            message={successMessageUpdateCompanyInfo}
            setMessage={setSuccessMessageUpdateCompanyInfo}
            isMobile={isMobile}
            locale={locale}
            theme={theme}
          />
          <Box
            sx={{
              padding: { xs: 2, sm: 3 },
              ...(!showAlertMessageIndustryTypes &&
                !errorMessageUpdateCompanyInfo && {
                  paddingTop: { xs: 0, sm: 0 },
                }),
            }}
          >
            {showAlertMessageIndustryTypes && (
              <ServerAlertMessage
                message={t(`errors.errorLoadingIndustryTypes`)}
                isLoading={isLoadingIndustryTypes}
                refetch={fetchIndustryTypes}
                alertRef={messageRef}
              />
            )}
            {errorMessageUpdateCompanyInfo && (
              <AlertMessage
                type="error"
                alertRef={messageRef}
                action={() => setErrorMessageUpdateCompanyInfo('')}
                iconAlignment="baseline"
                locale={locale}
                theme={theme}
              >
                {errorMessageUpdateCompanyInfo}
              </AlertMessage>
            )}
            {isError && <ErrorLoadingData action={fetchCompanyInfo} />}
            {!isError && (
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} style={{ marginTop: '24px' }}>
                  <FormikTextField
                    label={t('screens.businessInfo.businessName')}
                    id="organisationName"
                    required
                    name="organisationName"
                    objectId={userData.profile.sub}
                    autoComplete="organization"
                    formName="companyInfo"
                    helperText={t('screens.businessInfo.businessNameHelpText')}
                  />
                  <Grid container spacing={{ xs: 1, sm: 3 }}>
                    <Grid item xs={12} sm={6}>
                      <FormikSelect
                        label={t('screens.businessInfo.businessCategory')}
                        id="industryType"
                        required
                        name="industryType"
                        options={industryTypes}
                        objectId={userData.profile.sub}
                        formName="companyInfo"
                        helperText={t('screens.businessInfo.businessCategoryHelpText')}
                        isLoading={isLoadingIndustryTypes}
                        loadingText="screens.businessInfo.loadingIndustryTypes"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ '& > *': { width: '100%' } }}>
                      <FormikDatePicker
                        label={t('screens.businessInfo.startTradingDate')}
                        id="startTradingDate"
                        required
                        name="startTradingDate"
                        type="text"
                        objectId={userData.profile.sub}
                        formName="companyInfo"
                        helperText={t('screens.businessInfo.tradingDateHelpText')}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'flex-end',
                    }}
                  >
                    {hasUnsavedChanges && (
                      <AlertMessage
                        type="warning"
                        iconAlignment="baseline"
                        locale={locale}
                        theme={theme}
                      >
                        {t('errors.unsavedChanges')}
                      </AlertMessage>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: { xs: 2, sm: 0 },
                        ...(hasUnsavedChanges && { marginLeft: 2 }),
                      }}
                    >
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          if (!isUpdatingCompanyInfo) cancelUpdate();
                        }}
                        sx={{ marginRight: '16px' }}
                      >
                        {t('actions.cancel')}
                      </Button>
                      <Button color="primary" variant="contained" type="submit" disableElevation>
                        {isUpdatingCompanyInfo && (
                          <>
                            <CircularProgress
                              aria-label="Updating business info"
                              aria-live="polite"
                              aria-busy={isUpdatingCompanyInfo}
                              data-testid="loading-icon"
                              color="inherit"
                              size="20px"
                              sx={{ marginRight: '8px' }}
                            />
                            {t('actions.saving')}
                          </>
                        )}
                        {!isUpdatingCompanyInfo && t('actions.save')}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </FormikProvider>
            )}
          </Box>
        </>
      )}
    </Card>
  );
};

export default BusinessInfo;
