import * as React from "react";
import {ReactElement, useCallback, useState} from "react";
import {AlertColor} from "@mui/material";

export interface Banner {
    bannerType: AlertColor;
    bannerTitle: string;
    bannerMessage?: string;
    bannerIcon?: ReactElement;
    bannerButtonText?: string;
    onClickButton?: () => void;
}

export const BannerContext = React.createContext({
    banners: {}
} as {
    banners: Record<string, Banner>;
    addBanner?: (key: string, banner: Banner) => void;
    removeBanner?: (key: string) => void;
    hasBanner?: (key: string) => boolean;
});


const BannerContextProvider = ({ children }: { children: JSX.Element }) => {
    const [ banners, setBanners ] = useState<Record<string, Banner>>({});

    const addBanner = useCallback((key: string, banner: Banner) => {
        setBanners((prevBanners) => {
            if (Object.keys(prevBanners).includes(key)) return prevBanners;
            return {
                ...prevBanners,
                [key]: banner
            }
        });
    }, [setBanners]);

    const removeBanner = useCallback((key: string) => {
        setBanners((prevBanners) => {
            if (!Object.keys(prevBanners).includes(key)) return prevBanners;
            const ret = {...prevBanners};
            delete ret[key];
            return ret;
        })
    }, [setBanners]);

    const hasBanner = useCallback((key: string) => Object.keys(banners).includes(key), [banners]);

    return <BannerContext.Provider value={{banners, addBanner, removeBanner, hasBanner}}>{children}</BannerContext.Provider>
}

export default BannerContextProvider;
