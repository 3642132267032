import { Box, Card, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UIContext } from '../../../context/UIContext';
import Image from '../../../img/tutorials/network-6-1.png';
import Image2 from '../../../img/tutorials/network-6-2.png';

const NetworkWifiSetup = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(UIContext);

  return (
    <Card
      variant="outlined"
      sx={{
        padding: '40px',
      }}
    >
      <Grid container direction="row" alignItems="stretch" spacing={4}>
        <Grid container item direction="row" justifyContent="center" sm="auto">
          <Box
            component="img"
            mr={isMobile ? 0 : 2}
            sx={{
              width: 256.5,
              height: 456,
            }}
            alt="Terminal"
            src={Image}
          />
          <Box
            component="img"
            sx={{
              width: 256.5,
              height: 456,
            }}
            alt="Terminal"
            src={Image2}
          />
        </Grid>
        <Grid container item xs={12} sm direction="column" justifyContent="space-between">
          <Box>
            <Typography px={2} pb={1} variant="h2">
              {t('steps.tutorials.network.title')}
            </Typography>
            <Typography variant="body1" px={2}>
              {t('steps.tutorials.network.paragraph-6')}
            </Typography>

            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              {[
                'steps.tutorials.network.listItem',
                'steps.tutorials.network.listItem-2',
                'steps.tutorials.network.listItem-3',
                'steps.tutorials.network.listItem-6',
              ].map((str) => (
                <ListItem key={str} disablePadding sx={{ display: 'list-item' }}>
                  <ListItemText primary={<Trans i18nKey={str} />} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NetworkWifiSetup;
