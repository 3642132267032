import React from 'react'
import Image from '../../../img/tutorials/saturn-pro.png'
import Network from '../../tutorials/steps/Network'
import PPComplete from '../../tutorials/steps/Complete';
import YourStore from '../../tutorials/steps/YourStore';
import { TutorialStepConfig } from '../steps';
import TestingCloudSync from '../../tutorials/steps/TestingCloudSync';
import TestingPay from '../../tutorials/steps/TestingPay';
import TestingReady from '../../tutorials/steps/TestingReady';
import Troubleshooting from '../../tutorials/steps/Troubleshooting';
import NetworkWireless from '../../tutorials/steps/NetworkWireless';

const addPath = (stepKey: string) => `/tutorials/saturn-pro/${stepKey}`

export default {
    header: {
        title: 'Epos Now Payment Pro',
        subtitle: 'Saturn S1F2',
        image: Image
    },
    stepper: [
        {
            stepKey: addPath(`network`),
            stepOrder: 0,
            title: 'steps.tutorials.network.title',
            pointsTo: { next: [addPath(`network-2`)], previous: ''},
            component: <NetworkWireless />
        },
        {
            stepKey: addPath(`network-2`),
            stepOrder: 1,
            title: 'steps.tutorials.network.title',
            pointsTo: { next: [addPath(`your-store`)], previous: addPath(`network`)},
            component: <Network />
        },
        {
            stepKey: addPath(`your-store`),
            stepOrder: 2,
            title: 'steps.tutorials.yourStore.title',
            pointsTo: { next: [addPath(`testing`)], previous: addPath(`network-2`)},
            component: <YourStore />
        },
        {
            stepKey: addPath(`testing`),
            stepOrder: 3,
            title: 'steps.tutorials.testing.title',
            pointsTo: { next: [addPath(`testing-2`)], previous: addPath(`your-store`)},
            component: <TestingCloudSync/>
        },
        {
            stepKey: addPath(`testing-2`),
            stepOrder: 4,
            title: 'steps.tutorials.testing.title',
            pointsTo: { next: [addPath(`testing-3`)], previous: addPath(`testing`)},
            component: <TestingPay/>
        },
        {
            stepKey: addPath(`testing-3`),
            stepOrder: 5,
            title: 'steps.tutorials.testing.title',
            pointsTo: { next: [addPath(`troubleshooting`)], previous: addPath(`testing-2`)},
            component: <TestingReady/>
        },
        {
            stepKey: addPath(`troubleshooting`),
            stepOrder: 6,
            title: 'steps.tutorials.troubleshooting.title',
            pointsTo: { next: [addPath(`troubleshooting-2`)], previous: addPath(`testing-3`)},
            component: <Troubleshooting/>
        },
        {
            stepKey: addPath(`troubleshooting-2`),
            stepOrder: 7,
            title: 'steps.tutorials.troubleshooting.title',
            finishStep: true,
            pointsTo: { next: ['/tutorials'], previous: addPath(`troubleshooting`)},
            component: <PPComplete/>
        }
    ]
} as TutorialStepConfig;