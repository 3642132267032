import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TimerOutlined } from '@mui/icons-material';

const Maintenance = () => {
  const { t } = useTranslation();

  document.title = t('app.pageTitle', { name: t('nav.maintenanceMode') });

  return (
    <Card
      variant="outlined"
      sx={{
        textAlign: 'center',
      }}
    >
      <CardContent
        sx={{
          padding: 3,
          ':last-child': {
            paddingBottom: 1,
          },
        }}
      >
        <TimerOutlined
          fontWeight={700}
          sx={{
            fontSize: '300%',
            marginBottom: 1,
          }}
        />
        <CardHeader
          title={t('maintenance.title')}
          titleTypographyProps={{ variant: 'h1' }}
          sx={{
            padding: 0,
            marginBottom: 4,
          }}
        />
        <Typography fontWeight="600" mb={3}>
          {t('maintenance.subtitle')}
        </Typography>
        <Typography variant="body2" mb={-3}>
          {t('maintenance.content')}
        </Typography>
      </CardContent>
      {sessionStorage.getItem('disableNav') !== 'true' && (
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 3,
          }}
        >
          <Button
            component={Link}
            href={process.env.REACT_APP_LINK_STATUS_PAGE}
            color="primary"
            variant="contained"
            disableElevation
          >
            {t('maintenance.statusButton')}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default Maintenance;
