import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Card,
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  useTheme,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import P400Plus from '../../img/P400Plus.png';
import V400m from '../../img/V400m.png';
import S1F2 from '../../img/S1F2.png';
import AMS1 from '../../img/AMS1.png';
import NYC1 from '../../img/NYC1.png';
import { TerminalType, TerminalTypeForm } from '../../types';
import {
  FormikSelect,
  FormikTextField,
  terminalValidationSchema,
} from '../../components/FormikComponents';
import { AddressOption } from '../addresses/types/addressTypes';
import terminalNames from './terminalNames';
import { UserContext } from '../../context/UserContext';

const emptyTerminal = {
  SerialNumber: '',
  Model: '',
  LocationId: -1,
  UniqueName: '',
};

const Terminal = ({
  addresses,
  isLoadingAddresses,
  terminalData,
  updateStepError,
  setSuccessMessageUpdateTerminal,
}: {
  addresses: AddressOption[];
  isLoadingAddresses: boolean;
  terminalData: TerminalType;
  updateStepError: (message: string) => void;
  setSuccessMessageUpdateTerminal: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userData } = useAuth();
  const { apiFetch, locale } = useContext(UserContext);
  const queryClient = useQueryClient();
  const [errorMessageUpdateTerminal, setErrorMessageUpdateTerminal] = useState<string>('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [terminal, setTerminal] = useState<TerminalType>(emptyTerminal);
  const [updateTerminalData, setUpdateTerminalData] = useState<TerminalType>(emptyTerminal);
  const [adyenStoreCreated, setAdyenStoreCreated] = useState(false);
  const messageRef = useRef<null | HTMLElement>(null);
  const terminalName = terminalNames.find((device) => device.model === terminalData.Model)?.name;
  const terminalImages = [
    {
      name: 'P400Plus',
      img: P400Plus,
    },
    {
      name: 'V400m',
      img: V400m,
    },
    {
      name: 'S1F2',
      img: S1F2,
    },
    {
      name: 'AMS1',
      img: AMS1,
    },
  ];
  const terminalImage = terminalImages.find((device) => device.name === terminalData.Model)?.img;

  useEffect(() => {
    if (errorMessageUpdateTerminal && messageRef.current)
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [errorMessageUpdateTerminal]);

  const removeSavedTerminal = () => {
    const savedTerminals = JSON.parse(sessionStorage.getItem('terminals') || '{}');
    delete savedTerminals[`${terminalData.SerialNumber}`];
    sessionStorage.setItem('terminals', JSON.stringify(savedTerminals));
  };

  const { isLoading: isUpdatingTerminal, mutate: updateTerminal } = useMutation(
    (data: TerminalType) =>
      apiFetch(
        `${process.env.REACT_APP_API_PAYMENTS}v1/payments/terminal/update-terminals`,
        true,
        [data],
        'POST'
      ),
    {
      async onSuccess() {
        removeSavedTerminal();
        await queryClient.invalidateQueries(['terminals']);
        setErrorMessageUpdateTerminal('');
        setSuccessMessageUpdateTerminal(t('screens.terminal.successUpdateTerminal'));
        setHasUnsavedChanges(false);
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error updating terminal'), {
          extra: { error: JSON.stringify(err) },
        });
        setErrorMessageUpdateTerminal(t('errors.errorUpdatingTerminal'));
      },
    }
  );

  const { isLoading: isCreatingStoreForLocation, mutate: createStoreForLocation } = useMutation(
    (locationId: number) =>
      apiFetch(
        `${process.env.REACT_APP_API_PAYMENTS}v1/payments/store/set-store-for-location`,
        true,
        { locationId },
        'POST'
      ),
    {
      onSuccess() {
        setAdyenStoreCreated(true);
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;

        const invalidFields = err.errors?.InvalidFields;

        if (!invalidFields || invalidFields?.length === 0 || !invalidFields[0].InvalidField) {
          Sentry.captureException(new Error('Error creating adyen store'), {
            extra: { error: JSON.stringify(err) },
          });
          setErrorMessageUpdateTerminal(t('errors.errorCreatingAdyenStore'));
          return;
        }

        setErrorMessageUpdateTerminal(
          `${t('errors.errorAssigningLocation')} ${t([
            `errors.creatingStore.${invalidFields[0].InvalidField.Name.toLowerCase()}`,
            'errors.creatingStore.invalid',
          ])} ${t('errors.errorLocationInformation')}`
        );
      },
    }
  );

  useEffect(() => {
    if (!adyenStoreCreated) return;

    updateTerminal(updateTerminalData);
    setAdyenStoreCreated(false);
  }, [adyenStoreCreated]);

  const formik = useFormik({
    initialValues: {
      ...terminal,
      UniqueName: terminal.UniqueName || '',
      LocationId: terminal.LocationId?.toString() || '',
    },
    validationSchema: terminalValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (isCreatingStoreForLocation || isUpdatingTerminal || adyenStoreCreated) return;

      actions.setSubmitting(false);

      const newValues = terminalValidationSchema.cast(values) as TerminalTypeForm;

      if (!addresses.find((address) => address.value === newValues.LocationId)) {
        formik.setValues({
          ...formik.values,
          LocationId: '',
        });
        return;
      }

      setUpdateTerminalData({
        ...newValues,
        LocationId: parseInt(newValues.LocationId, 10),
      });

      createStoreForLocation(parseInt(newValues.LocationId, 10));
    },
  });

  const checkStoredDataHasChanges = (newData: TerminalTypeForm) =>
    (newData.UniqueName && newData.UniqueName !== terminalData?.UniqueName) ||
    (!newData.UniqueName && !!terminalData?.UniqueName) ||
    (newData.LocationId &&
      newData.LocationId.toString() !== terminalData?.LocationId?.toString()) ||
    (!newData.LocationId && !!terminalData?.LocationId?.toString());

  useEffect(() => {
    setHasUnsavedChanges(false);

    if (!terminalData) return;

    setTerminal(terminalData);
    formik.setValues({
      ...terminalData,
      UniqueName: terminalData.UniqueName || '',
      LocationId: terminalData.LocationId?.toString() || '',
    });
  }, [terminalData]);

  const cancelUpdate = () => {
    if (!terminalData) return;

    updateStepError('');
    setHasUnsavedChanges(false);
    setTerminal(terminalData);
    removeSavedTerminal();

    formik.setValues({
      ...terminalData,
      UniqueName: terminalData.UniqueName || '',
      LocationId: terminalData.LocationId?.toString() || '',
    });
  };

  useEffect(() => {
    if (!terminalData || !terminal.SerialNumber) return;

    if (formik.values.LocationId) updateStepError(t('errors.errorNeedsToSave'));
    setHasUnsavedChanges(checkStoredDataHasChanges(formik.values));
  }, [formik?.values?.LocationId, formik?.values?.UniqueName]);

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: { xs: 2, sm: 3 },
      }}
    >
      {errorMessageUpdateTerminal && (
        <Box marginBottom={2}>
          <AlertMessage
            type="error"
            alertRef={messageRef}
            action={() => setErrorMessageUpdateTerminal('')}
            iconAlignment="baseline"
            locale={locale}
            theme={theme}
          >
            {errorMessageUpdateTerminal}
          </AlertMessage>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="h2" marginBottom={1}>
            {t(`${terminalName}`)}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{terminalData.Model}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '80px',
            paddingLeft: '10px',
          }}
        >
          <Box component="img" alt={t(`${terminalName}`) || 'Terminal'} src={terminalImage} />
        </Box>
      </Box>
      <FormikProvider value={formik}>
        <Form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Box>
            <TextField
              id={`SerialNumber-${terminalData.SerialNumber}`}
              label={t('screens.terminal.serialNumber')}
              defaultValue={terminalData.SerialNumber}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              variant="standard"
              sx={{
                marginBottom: 2,
                '.MuiInputLabel-root.Mui-focused': {
                  color: 'text.secondary',
                },
                '.MuiInputLabel-root': {
                  color: 'text.secondary',
                },
              }}
            />
            <FormikTextField
              label={t('screens.terminal.terminalName')}
              id={`UniqueName-${terminalData.SerialNumber}`}
              objectId={terminalData.SerialNumber}
              formName="terminals"
              name="UniqueName"
            />
            <FormikSelect
              label={t('screens.terminal.location')}
              id={`LocationId-${terminalData.SerialNumber}`}
              required
              name="LocationId"
              objectId={terminalData.SerialNumber}
              formName="terminals"
              options={addresses}
              isLoading={isLoadingAddresses}
              loadingText="screens.terminal.loadingLocations"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            {hasUnsavedChanges && (
              <AlertMessage type="warning" iconAlignment="baseline" locale={locale} theme={theme}>
                {t('errors.unsavedChanges')}
              </AlertMessage>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
              <Button
                color="primary"
                type="button"
                onClick={() => {
                  if (!isUpdatingTerminal && !isCreatingStoreForLocation && !adyenStoreCreated)
                    cancelUpdate();
                }}
                sx={{ marginRight: '16px' }}
              >
                {t('actions.cancel')}
              </Button>
              <Button color="primary" variant="contained" type="submit" disableElevation>
                {(isCreatingStoreForLocation || isUpdatingTerminal || adyenStoreCreated) && (
                  <>
                    <CircularProgress
                      aria-label={t('screens.terminal.updatingTerminal')}
                      aria-live="polite"
                      aria-busy={
                        isUpdatingTerminal || isCreatingStoreForLocation || adyenStoreCreated
                      }
                      data-testid="loading-icon"
                      color="inherit"
                      size="20px"
                      sx={{ marginRight: '8px' }}
                    />
                    {t('actions.saving')}
                  </>
                )}
                {!isCreatingStoreForLocation &&
                  !isUpdatingTerminal &&
                  !adyenStoreCreated &&
                  t('actions.save')}
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </Card>
  );
};

export default Terminal;
