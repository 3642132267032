import React, { ReactElement, useContext } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@mui/material';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import { UIContext } from '../context/UIContext';
import { ReactComponent as MobilePhone } from '../img/icons/Phone-Barcode.svg';
import { ReactComponent as WhiteMobilePhone } from '../img/icons/Phone-Barcode-white.svg';
import { UserContext } from '../context/UserContext';

export interface QRDialogProps {
  open: boolean;
  url: string;
  title: string;
  description?: string;
  showFullUrlMessage?: boolean;
  icon?: ReactElement;
  primaryAction: () => void;
  secondaryAction?: () => void;
  onClose: () => void;
  primaryButtonText: string;
  secondaryButtonText: string;
  fullscreenDialog?: boolean;
}

const QRDialog = ({
  open,
  url,
  title,
  description,
  showFullUrlMessage,
  icon,
  primaryAction,
  secondaryAction,
  onClose,
  primaryButtonText,
  secondaryButtonText,
  fullscreenDialog = false,
}: QRDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile, colorMode } = useContext(UIContext);
  const { locale } = useContext(UserContext);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { padding: '16px' } }}
      fullScreen={fullscreenDialog}
    >
      <DialogTitle
        id="alert-dialog-title"
        component="h3"
        variant="h3"
        sx={{
          display: 'flex',
          textAlign: 'center',
          ...(fullscreenDialog && { marginTop: '1.5rem' }),
          flexDirection: 'column',
        }}
      >
        <Box sx={{ marginBottom: isMobile ? '2.5em' : '1em' }}>
          {icon || colorMode === 'light' ? <MobilePhone /> : <WhiteMobilePhone />}
        </Box>
        <Box>{title}</Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
          {description}
          {showFullUrlMessage && (
            <Box
              sx={{
                wordBreak: 'break-word',
                fontWeight: 600,
                color: 'primary.main',
              }}
            >
              {document.location.href}
            </Box>
          )}
        </DialogContentText>
        {url && url.length > 0 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '32px',
            }}
          >
            <QRCode value={url} size={160} />
          </Box>
        )}

        {!showFullUrlMessage && (
          <AlertMessage
            type="warning"
            styles={{ marginTop: '24px' }}
            iconAlignment="baseline"
            locale={locale}
            theme={theme}
          >
            <>
              {t('actions.pleaseLoginTo')}&nbsp;<strong>{url}</strong>&nbsp;
              {t('actions.inMobileLaptopOrDesktop')}
            </>
          </AlertMessage>
        )}
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'center' : 'flex-end',
            marginBottom: isMobile ? '2.5em' : '0',
            gap: '0.5rem',
          }}
        >
          <Button sx={{ padding: '12px 24px', textTransform: 'none' }} onClick={secondaryAction}>
            {secondaryButtonText}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            sx={{ padding: '12px 24px', textTransform: 'none' }}
            onClick={() => {
              if (primaryAction) primaryAction();
              onClose();
            }}
            autoFocus
          >
            {primaryButtonText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default QRDialog;
