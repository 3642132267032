import AccordionDetails from '@mui/material/AccordionDetails';
import { FormControlLabel, Radio, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { UIContext } from '../context/UIContext';

export interface AccordionPickerOption {
  id: string;
  name: string;
  description?: string;
}

export interface AccordionPickerProps {
  options: AccordionPickerOption[];
  onSelectOption: (option: AccordionPickerOption) => void;
  selectedOption?: string;
}

const AccordionPicker = (props: AccordionPickerProps) => {
  const { options, onSelectOption, selectedOption } = props;
  const [checkedOption, setCheckedOption] = useState<string | undefined>(selectedOption);
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);
  const { disableAnimation } = useContext(UIContext);

  const toggleOption = useCallback(
    (option: AccordionPickerOption) => {
      setExpandedAccordions((prevOptions) => {
        if (prevOptions.includes(option.id)) {
          return prevOptions.filter((it) => it !== option.id);
        }
        return prevOptions.concat([option.id]);
      });
    },
    [setExpandedAccordions]
  );

  const expandOption = useCallback(
    (option: AccordionPickerOption) => {
      setExpandedAccordions((prevOptions) => {
        if (prevOptions.includes(option.id)) {
          return prevOptions;
        }
        return prevOptions.concat([option.id]);
      });
    },
    [setExpandedAccordions]
  );

  return (
    <>
      {options.map((option, idx) => (
        <Accordion
          key={option.id}
          sx={{
            '&.MuiAccordion-root': {
              boxShadow: 'none',
              borderBottom: (theme) =>
                idx === options.length - 1 ? 'none' : `1px solid ${theme.palette.secondary.main}`,
            },
          }}
          TransitionProps={disableAnimation ? { timeout: 0 } : {}}
          disableGutters
          square
          expanded={expandedAccordions.includes(option.id)}
          onChange={(e) => {
            /* no op */
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon onClick={() => toggleOption(option)} />}>
            <FormControlLabel
              componentsProps={{
                typography: {
                  onClick: () => expandOption(option),
                  sx: { color: checkedOption === option.id ? 'text.primary' : 'text.secondary' },
                },
              }}
              onChange={() => {
                onSelectOption(option);
                setCheckedOption(option.id);
              }}
              value={checkedOption === option.id}
              control={
                <Radio checked={checkedOption === option.id} onClick={() => expandOption(option)} />
              }
              label={option.name}
            />
          </AccordionSummary>
          {option.description && (
            <AccordionDetails>
              <Typography
                sx={{
                  color: checkedOption === option.id ? 'text.primary' : 'text.secondary',
                  fontSize: '14px',
                  marginLeft: '33px',
                }}
              >
                {option.description}
              </Typography>
            </AccordionDetails>
          )}
        </Accordion>
      ))}
    </>
  );
};

export default AccordionPicker;
