import { Box, IconButton, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import * as React from 'react';
import { CSSProperties, ReactElement } from 'react';
import { UIContext } from '../context/UIContext';

export interface DrawerTitlebarProps {
  icon?: ReactElement;
  title: string;
  onClose?: () => void;
  sx?: CSSProperties;
}

const titleStyle = {
  display: 'inline-block',
  width: 'calc(100% - 32px)',
  position: 'sticky' as const,
  backgroundColor: 'background.paper',
  paddingBottom: '12px',
  paddingLeft: '16px',
  paddingRight: '16px',
  zIndex: 999,
  top: 0,
  paddingTop: '16px',
};

const headerStyle: CSSProperties = {
  display: 'inline-block',
  paddingLeft: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '18px',
  paddingTop: '2px',
  paddingBottom: '12px',
  position: 'absolute',
};

const DrawerTitlebar = (props: DrawerTitlebarProps) => {
  const { icon, title, sx, onClose } = props;
  const { colorMode } = React.useContext(UIContext);

  return (
    <Box
      sx={{
        ...titleStyle,
        ...sx,
        borderBottom: (theme) =>
          `1px ${
            colorMode === 'light' ? theme.palette.grey['12p'] : theme.palette.grey['23p']
          } solid`,
      }}
    >
      {icon ? (
        <span style={{ color: colorMode === 'light' ? '#595959' : '#B2B2B2' }}>{icon}</span>
      ) : (
        <span
          style={{ display: 'inline-block', height: '24px', width: '1px', marginRight: '-1px' }}
        />
      )}
      <Typography sx={headerStyle} variant="h2">
        {title}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="Close"
          color="inherit"
          data-testid="closeDrawer"
          size="small"
          onClick={onClose}
          sx={{ position: 'absolute', left: '100%', marginLeft: '-42px', marginTop: '-2px' }}
        >
          <CloseOutlinedIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};

export default DrawerTitlebar;
