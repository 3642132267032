import {Breadcrumb, BreadcrumbHint} from "@sentry/types/types/breadcrumb";

const breadcrumbHook = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => ({
    ...breadcrumb,
    data: {
        ...breadcrumb.data,
        from: breadcrumb.data && breadcrumb.data.from && breadcrumb.data.from.includes("/?code=") ? "<oauth callback>" : breadcrumb.data?.from
    }
});

export default breadcrumbHook;
