import { AlertColor, Card, Divider, Link, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { CSSProperties, ReactNode, useCallback, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { AlertMessage } from '@eposnow/ui-core';
import { UserContext } from '../context/UserContext';

// copied straight from react-markdown module because importing a pure js file breaks jest and couldn't fix it
function uriTransformer(uri: string) {
  const protocols = ['http', 'https', 'mailto', 'tel'];
  const url = (uri || '').trim();
  const first = url.charAt(0);

  if (first === '#' || first === '/') {
    return url;
  }

  const colon = url.indexOf(':');
  if (colon === -1) {
    return url;
  }

  let index = 0;

  while (index < protocols.length) {
    const protocol = protocols[index];

    if (colon === protocol.length && url.slice(0, protocol.length).toLowerCase() === protocol) {
      return url;
    }
    index += 1;
  }

  index = url.indexOf('?');
  if (index !== -1 && colon > index) {
    return url;
  }

  index = url.indexOf('#');
  if (index !== -1 && colon > index) {
    return url;
  }

  // eslint-disable-next-line no-script-url
  return 'javascript:void(0)';
}

const LinkHandler = (props: {
  href?: string;
  children?: ReactNode;
  infoHandlers?: Record<string, () => void>;
  sx?: Record<string, CSSProperties>;
}) => {
  const { href, children, infoHandlers, sx } = props;
  const theme = useTheme();
  const { locale } = React.useContext(UserContext);

  if (!href) {
    return <span style={{ ...(sx && sx.p ? sx.p : {}) }}>{children}</span>;
  }
  if (
    href.startsWith('info:') &&
    infoHandlers &&
    Object.keys(infoHandlers).includes(href.substring(href.indexOf(':') + 1))
  ) {
    const handler = infoHandlers[href.substring(href.indexOf(':') + 1)];
    return (
      <button
        type="button"
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          textDecoration: 'none',
          display: 'inline',
          ...(sx && sx.infoLink ? sx.infoLink : {}),
        }}
        onClick={handler}
      >
        {children}&nbsp;
        <InfoIcon
          sx={{
            fontSize: sx && sx.fontSize ? sx.fontSize : '16px',
            fill: 'grey',
            verticalAlign: 'top',
            marginTop:
              sx && sx.fontSize
                ? `${(parseInt(sx.fontSize.toString(), 10) / 4).toString(10)}px`
                : '2px',
          }}
        />
      </button>
    );
  }
  if (href.startsWith('banner:')) {
    const bannerType = href.substring(href.indexOf(':') + 1) as unknown as AlertColor;
    return (
      <AlertMessage
        type={bannerType}
        styles={{ ...(sx && sx[bannerType] ? sx[bannerType] : {}) }}
        iconAlignment="baseline"
        locale={locale}
        theme={theme}
      >
        {children}
      </AlertMessage>
    );
  }
  return (
    <Link href={href} target="_blank" style={{ ...(sx && sx.a ? sx.a : {}) }}>
      {children}
    </Link>
  );
};

const Markdown = (props: {
  children: string;
  css?: any;
  infoHandlers?: Record<string, () => void>;
  images?: Record<string, string>;
}) => {
  const { children, css, infoHandlers, images } = props;

  const styles = useMemo(
    () => ({
      ...css,
      body: {
        background: 'rgba(255, 255, 255, 0)',
        ...(css && css.body ? css.body : {}),
      },
      h1: {
        padding: '4px 4px 0 0',
        marginBottom: '8px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '32px',
        ...(css && css.h1 ? css.h1 : {}),
      },
      h2: {
        padding: '4px 4px 0 0',
        marginBottom: '8px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '24px',
        ...(css && css.h1 ? css.h2 : {}),
      },
      h3: {
        padding: '4px 4px 0 0',
        marginBottom: '8px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '18px',
        ...(css && css.h3 ? css.h3 : {}),
      },
      h4: {
        padding: '4px 4px 0 0',
        marginBottom: '8px',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '18px',
        ...(css && css.h4 ? css.h4 : {}),
      },
      h5: {
        padding: '4px 4px 0 0',
        marginBottom: '8px',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '16px',
        ...(css && css.h5 ? css.h5 : {}),
      },
      p: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '14px',
        ...(css && css.p ? css.p : {}),
      },
      ul: {
        paddingInlineStart: '32px',
        ...(css && css.ul ? css.ul : {}),
      },
      li: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '16px',
        marginBottom: '12px',
        ...(css && css.li ? css.li : {}),
      },
      blockquote: {
        fontFamily: 'Open Sans',
        padding: '12px 16px 16px 16px',
        marginTop: '16px',
        ...(css && css.blockquote ? css.blockquote : {}),
      },
      b: {
        fontWeight: 600,
        ...(css && css.b ? css.b : {}),
      },
      strong: {
        fontWeight: 600,
        ...(css && css.strong ? css.strong : {}),
      },
      img: {
        ...(css && css.img ? css.img : {}),
      },
      infoLink: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'inline',
        margin: 0,
        padding: 0,
        fontFamily: 'Open Sans',
        ...(css && css.infoLink ? css.infoLink : {}),
      },
      a: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontWeight: 600,
        color: 'black',
        textDecoration: 'underline',
        display: 'inline',
        margin: 0,
        padding: 0,
        fontFamily: 'Open Sans',
        ...(css && css.a ? css.a : {}),
      },
      span: {
        fontFamily: 'Open Sans',
        ...(css && css.span ? css.span : {}),
      },
      info: {
        ...(css && css.info ? css.info : {}),
      },
      warning: {
        ...(css && css.warning ? css.warning : {}),
      },
      error: {
        ...(css && css.error ? css.error : {}),
      },
      success: {
        ...(css && css.success ? css.success : {}),
      },
      hr: {
        ...(css && css.hr ? css.hr : {}),
      },
    }),
    [css]
  );

  const linkFactory = useCallback(
    (p: { href?: string; children?: ReactNode }) => (
      <LinkHandler href={p.href} infoHandlers={infoHandlers} sx={styles}>
        {p.children}
      </LinkHandler>
    ),
    [infoHandlers]
  );

  const blockQuoteFactory = useCallback(
    (p: { children?: ReactNode }) => (
      <Card variant="outlined" sx={styles.blockquote}>
        {p.children}
      </Card>
    ),
    [styles]
  );

  const imageFactory = useCallback(
    (p: { src?: string; alt?: string }) => {
      const { src, alt } = p;
      if (
        src &&
        src.startsWith('image') &&
        images &&
        Object.keys(images).includes(src.substring(src.indexOf(':') + 1))
      ) {
        const flags = src.substring(0, src.indexOf(':')).split('.');
        const style: CSSProperties = {};
        if (flags.includes('left')) {
          style.float = 'left';
          style.marginTop = '-8px';
        }
        if (flags.includes('right')) {
          style.float = 'right';
          style.marginTop = '-8px';
        }
        if (flags.includes('fit')) {
          style.width = '100%';
          style.marginTop = '16px';
        }
        return (
          <img
            src={images[src.substring(src.indexOf(':') + 1)]}
            alt={alt}
            style={{ ...styles.img, ...style }}
          />
        );
      }
      return <img src={src} alt={alt} style={{ ...styles.img }} />;
    },
    [images, styles]
  );

  const h1Factory = useCallback(
    (p: { children?: ReactNode }) => (
      <Typography component="h1" variant="h1" sx={{ ...styles.h1 }}>
        {p.children}
      </Typography>
    ),
    [styles]
  );
  const h2Factory = useCallback(
    (p: { children?: ReactNode }) => (
      <Typography component="h2" variant="h2" sx={{ ...styles.h2 }}>
        {p.children}
      </Typography>
    ),
    [styles]
  );
  const h3Factory = useCallback(
    (p: { children?: ReactNode }) => (
      <Typography component="h3" variant="h3" sx={{ ...styles.h3 }}>
        {p.children}
      </Typography>
    ),
    [styles]
  );
  const h4Factory = useCallback(
    (p: { children?: ReactNode }) => (
      <Typography component="h4" variant="h4" sx={{ ...styles.h4 }}>
        {p.children}
      </Typography>
    ),
    [styles]
  );
  const h5Factory = useCallback(
    (p: { children?: ReactNode }) => (
      <Typography component="h5" variant="h5" sx={{ ...styles.h5 }}>
        {p.children}
      </Typography>
    ),
    [styles]
  );
  const pFactory = useCallback(
    (p: { children?: ReactNode }) => (
      <Typography component="span" sx={{ ...styles.p }}>
        {p.children}
      </Typography>
    ),
    [styles]
  );
  const spanFactory = useCallback(
    (p: { children?: ReactNode }) => (
      <Typography component="span" sx={{ ...styles.span }}>
        {p.children}
      </Typography>
    ),
    [styles]
  );
  const ulFactory = useCallback(
    (p: { children?: ReactNode }) => <ul style={{ ...styles.ul }}>{p.children}</ul>,
    [styles]
  );
  const liFactory = useCallback(
    (p: { children?: ReactNode }) => <li style={{ ...styles.li }}>{p.children}</li>,
    [styles]
  );
  const bFactory = useCallback(
    (p: { children?: ReactNode }) => <b style={{ ...styles.b }}>{p.children}</b>,
    [styles]
  );
  const strongFactory = useCallback(
    (p: { children?: ReactNode }) => <strong style={{ ...styles.strong }}>{p.children}</strong>,
    [styles]
  );
  const hrFactory = useCallback(
    (p: { children?: ReactNode }) => (
      <Divider sx={{ marginTop: '16px', marginBottom: '16px', ...styles.hr }} />
    ),
    [styles]
  );

  const linkTransformer = useCallback(
    (uri: string) =>
      uri.startsWith('info:') || uri.startsWith('banner:') ? uri : uriTransformer(uri),
    []
  );

  const imageTransformer = useCallback(
    (uri: string) =>
      uri.startsWith('image') || uri.startsWith('data:') ? uri : uriTransformer(uri),
    []
  );

  return (
    <ReactMarkdown
      components={{
        h1: h1Factory,
        h2: h2Factory,
        h3: h3Factory,
        h4: h4Factory,
        h5: h5Factory,
        p: pFactory,
        span: spanFactory,
        ul: ulFactory,
        li: liFactory,
        a: linkFactory,
        b: bFactory,
        strong: strongFactory,
        blockquote: blockQuoteFactory,
        img: imageFactory,
        hr: hrFactory,
      }}
      transformLinkUri={linkTransformer}
      transformImageUri={imageTransformer}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
