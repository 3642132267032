import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { enpIcon } from './kycNotStarted/assets';
import { PrepStage, useSteps } from '../../components/StepController';
import QRDialog from '../../components/QRDialog';
import { useUserCountry } from '../../helpers/useCountries';

const KycNotAvailableEposDialog = (props: { primaryAction: () =>  void }) => {
    const { primaryAction } = props;
    const { helpScreenAvailable } = useUserCountry();
    const { t } = useTranslation();
    const stepContext = useSteps();
    const qrUrl = useMemo(() => `${window.location.origin}/payments-verification`, []);

    return <QRDialog open
                     title={t("screens.verification.kycPreparation.notAvailableInThisDevice.title")}
                     description={t("screens.verification.kycPreparation.notAvailableInThisDevice.description")}
                     icon={<img src={enpIcon} alt={t("screens.verification.kycPreparation.notAvailableInThisDevice.logoText")} />}
                     primaryButtonText={t("actions.gotIt")}
                     onClose={() => stepContext.setPrepStage(helpScreenAvailable ? PrepStage.PREPARE_KYC : PrepStage.SELECT_BUSINESS_TYPE)}
                     primaryAction={primaryAction}
                     secondaryButtonText={t("actions.support")}
                     secondaryAction={() => window.open(process.env.REACT_APP_LINK_SUPPORT)}
                     url={qrUrl}
    />
}

export default KycNotAvailableEposDialog;
