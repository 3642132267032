import React, { useContext } from 'react';
import {
  Card,
  Typography,
  Button,
  Box,
  Grid,
  CardActionArea,
  Container,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { MainHeader } from '@eposnow/ui-core';
import P400Plus from '../../img/P400Plus.png';
import V400m from '../../img/V400m.png';
import S1F2 from '../../img/S1F2.png';
import S1F2Normal from '../../img/S1F2Normal.png';
import AMS1 from '../../img/AMS1.png';
import { useUserCountry } from '../../helpers/useCountries';
import { ReactComponent as Guide } from '../../img/icons/guide.svg';
import { UIContext } from '../../context/UIContext';

const TutorialButton = ({
  title,
  name,
  image,
  to,
}: {
  title: string;
  name: string;
  image: string;
  to: string;
}) => {
  const { colorMode } = useContext(UIContext);

  return (
    <Grid item xs={12} sm={6} display="flex">
      <Card
        elevation={0}
        sx={{
          width: '100%',
          border: (theme) =>
            `1px ${
              colorMode === 'light' ? theme.palette.grey['12p'] : theme.palette.grey['23p']
            } solid`,
        }}
      >
        <CardActionArea
          sx={{
            paddingX: { xs: 4, md: 6 },
            paddingY: { xs: 2, md: 3 },
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          component={RouterLink}
          to={to}
        >
          <Box sx={{ textAlign: 'start' }}>
            <Typography variant="h3" marginBottom={0.5}>
              {title}
            </Typography>
            <Typography>{name}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '80px',
              paddingLeft: '10px',
            }}
          >
            <Box component="img" alt={`${title} - ${name}`} src={image} />
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const Tutorials = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userCountry } = useUserCountry();
  const { colorMode } = useContext(UIContext);
  document.title = t('app.pageTitle', { name: t('nav.tutorials') });

  const terminals = [
    {
      title: 'screens.tutorials.eposNowPaymentsLink',
      name: 'P400',
      img: P400Plus,
      path: '/tutorials/link-p400/network',
    },
    {
      title: 'screens.tutorials.eposNowPaymentsPro',
      name: 'V400M',
      img: V400m,
      path: '/tutorials/pro-v400/network',
    },
    {
      title: 'screens.tutorials.eposNowPaymentsPro',
      name: 'Saturn S1F2',
      img: S1F2Normal,
      path: '/tutorials/saturn-pro/network',
    },
    {
      title: 'screens.terminal.eposNowPaymentsProPlus',
      name: 'Saturn S1F2',
      img: S1F2,
      path: '/tutorials/saturn-pro-plus/network',
    },
    {
      title: 'screens.terminal.eposNowPaymentsAir',
      name: 'AMS1',
      img: AMS1,
      path: '/tutorials/ams1-air/network',
    },
    {
      title: 'screens.terminal.eposNowPaymentsStandalone',
      name: 'AMS1',
      img: AMS1,
      path: '/tutorials/ams1-standalone/network',
    },
  ];

  if (userCountry === 'GB') {
    // Remove the V400m from the list
    terminals.splice(1, 1);
  }

  return (
    <>
      <MainHeader
        title={t('screens.tutorials.title')}
        styles={{ marginTop: { xs: 0, sm: 4, md: 6 }, marginBottom: { xs: 4, sm: 5 } }}
        theme={theme}
      >
        <Typography>{t('screens.tutorials.description')}</Typography>
      </MainHeader>
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: { xs: 2, sm: 3 },
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ marginBottom: 5 }}>
          <Guide color="primary" style={{ fontSize: '60px', marginBottom: 2 }} />
          <Typography variant="h2" sx={{ marginBottom: 1 }}>
            {t('screens.tutorials.terminalTutorials')}
          </Typography>
          <Typography>{t('screens.tutorials.selectTerminalTutorial')}</Typography>
        </Box>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          {terminals.map((terminal) => (
            <TutorialButton
              title={t(terminal.title)}
              name={terminal.name}
              image={terminal.img}
              key={`${t(terminal.title).toLowerCase()}-${terminal.name.toLowerCase()}`}
              to={terminal.path}
            />
          ))}
        </Grid>
      </Card>
      {sessionStorage.getItem('disableNav') !== 'true' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',

            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box
            sx={{
              border: `1px ${
                colorMode === 'light' ? theme.palette.grey['12p'] : theme.palette.grey['23p']
              } solid`,
              paddingY: 1,
              paddingX: { xs: 2, sm: 3 },
            }}
          >
            <Container
              sx={{
                paddingX: { xs: 0, lg: 3 },
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                component={Link}
                disableElevation
                href={process.env.REACT_APP_LINK_BACK_OFFICE}
                target="_self"
              >
                {t('screens.complete.goToBackoffice')}
              </Button>
            </Container>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Tutorials;
