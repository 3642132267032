import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  MobileStepper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Link,
  StepButton,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  Outlet,
  useOutletContext,
  Link as RouterLink,
} from 'react-router-dom';
import { UIContext } from '../context/UIContext';
import { TutorialStepConfig } from '../steps/config/steps';
import Fallback from './Fallback';
import QRDialog from './QRDialog';

const TutorialStepController = (props: any) => {
  const {
    stepConfig: { header, stepper },
  }: { stepConfig: TutorialStepConfig } = props;
  const { t } = useTranslation();
  const { isMobile, isSmallDevice, colorMode } = useContext(UIContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStepIndex, setActiveStepIdx] = useState(
    stepper.findIndex(({ stepKey }) => location.pathname === `${stepKey}`)
  );
  const [hasPreviousStep, setHasPreviousStep] = useState(false);
  const [hasNextStep, setHasNextStep] = useState(false);
  const [showQRAlert, setShowQRAlert] = useState(false);
  const uniqueSteps = stepper
    .filter((v, i, a) => a.findIndex((v2) => v2.title === v.title) === i)
    .map(({ title, stepKey }) => ({ title, stepKey }));
  const [currentUniqueStepIndex, setCurrentUniqueStepIndex] = useState(
    uniqueSteps.findIndex(({ title }) => title === stepper[activeStepIndex].title)
  );

  useEffect(() => {
    if (location.pathname !== `${stepper[activeStepIndex].stepKey}`) {
      setActiveStepIdx(stepper.findIndex(({ stepKey }) => location.pathname === `${stepKey}`));
    }
  }, [location.pathname]);

  const handleNext = (option = 0) => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    if (stepper[activeStepIndex].finishStep) {
      navigate(stepper[activeStepIndex].pointsTo.next[option]);
    } else {
      setActiveStepIdx((prevActiveStep) =>
        stepper.findIndex(
          ({ stepKey }) => stepKey === stepper[prevActiveStep].pointsTo.next[option]
        )
      );
    }
  };

  const handleBack = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    setActiveStepIdx((prevActiveStep) =>
      stepper.findIndex(({ stepKey }) => stepKey === stepper[prevActiveStep].pointsTo.previous)
    );
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    if (location.pathname !== `${stepper[activeStepIndex].stepKey}`) {
      navigate(stepper[activeStepIndex].stepKey);
    }
    setHasPreviousStep(!!stepper[activeStepIndex].pointsTo.previous);
    setHasNextStep(stepper[activeStepIndex].pointsTo.next.length === 1);
    setCurrentUniqueStepIndex(
      uniqueSteps.findIndex(({ title }) => title === stepper[activeStepIndex].title)
    );
  }, [activeStepIndex]);

  document.title = t('app.pageTitle', { name: t(header.title) });

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('location', 'TutorialStepController');
      }}
      fallback={<Fallback />}
    >
      {showQRAlert && (
        <QRDialog
          open
          title={t('steps.tutorials.qrCode.cta')}
          description={t('steps.tutorials.qrCode.scanOrVisit')}
          showFullUrlMessage
          primaryButtonText={t('actions.gotIt')}
          primaryAction={() => setShowQRAlert(false)}
          secondaryButtonText={t('actions.support')}
          secondaryAction={() => window.open(process.env.REACT_APP_LINK_SUPPORT)}
          url={document.location.href}
          onClose={() => setShowQRAlert(false)}
        />
      )}
      <Box>
        {!isMobile && (
          <Stepper
            nonLinear
            alternativeLabel={isSmallDevice}
            activeStep={currentUniqueStepIndex}
            sx={{
              mt: 5,
            }}
          >
            {uniqueSteps.map(({ title, stepKey }, index) => (
              <Step
                key={title}
                completed={index < currentUniqueStepIndex}
                sx={{
                  ...(index === 0 && {
                    paddingLeft: '8px',
                    paddingRight: 0,
                  }),

                  ...(index === uniqueSteps.length - 1 && {
                    paddingLeft: 0,
                    paddingRight: '8px',
                  }),
                  ':not(.Mui-completed) svg': {
                    color: (theme) =>
                      `${
                        currentUniqueStepIndex === index
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary
                      }`,
                  },
                  ':not(.Mui-completed) span': {
                    color: 'text.secondary',
                  },
                }}
              >
                <StepButton
                  sx={{
                    ...(index === 0 && {
                      paddingLeft: '8px',
                      paddingRight: '24px',
                    }),

                    ...(index === uniqueSteps.length - 1 && {
                      paddingLeft: '24px',
                      paddingRight: '8px',
                    }),
                  }}
                  onClick={() => {
                    navigate(`${stepKey}`);
                  }}
                >
                  {t(title)}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: isMobile ? 0 : 4,
            mb: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h2" paddingBottom={1}>
              {t(header.title)}
            </Typography>
            <Typography variant="body1">{t(header.subtitle)}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {!isMobile && (
              <Button
                color="primary"
                variant="outlined"
                disableElevation
                onClick={() => setShowQRAlert(true)}
                sx={{
                  height: 'fit-content',
                  marginX: '30px',
                  paddingY: { xs: '5px', sm: '9px' },
                  paddingX: { xs: 2, sm: 3 },
                }}
              >
                {t('steps.tutorials.qrCode.cta')}
              </Button>
            )}
            <Box component="img" alt="Terminal" src={header.image} />
          </Box>
        </Box>

        <Outlet
          context={{
            handleNext,
            handleBack,
            isFirstStep: hasPreviousStep,
            isLastStep: hasNextStep,
          }}
        />

        {!isMobile && (
          <Link
            rel="noreferrer noopener"
            aria-label={t('screens.tutorials.backToTutorials')}
            title={t('screens.tutorials.backToTutorials')}
            underline="none"
            to={'/tutorials' as any}
            component={RouterLink}
            color="primary"
            sx={{
              marginTop: 4,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ArrowBackIcon color="primary" sx={{ marginRight: '8px' }} />
            <Typography variant="h3">{t('steps.tutorials.header.backButton')}</Typography>
          </Link>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          {isMobile && (
            <MobileStepper
              variant="progress"
              LinearProgressProps={{ 'aria-label': 'steps progress bar' }}
              steps={stepper.length}
              position="static"
              activeStep={activeStepIndex}
              sx={{
                flexGrow: 1,
                padding: 0,
                '.MuiMobileStepper-progress': { width: '100%' },
              }}
              backButton={null}
              nextButton={null}
            />
          )}
          <Box
            sx={{
              border: (theme) =>
                `1px ${
                  colorMode === 'light' ? theme.palette.grey['12p'] : theme.palette.grey['23p']
                } solid`,
              paddingY: 1,
              paddingX: { xs: 2, sm: 3 },
            }}
          >
            <Container
              sx={{
                paddingX: { xs: 0, lg: 3 },
                display: 'flex',
                justifyContent: !hasPreviousStep ? 'flex-end' : 'space-between',
              }}
            >
              {hasPreviousStep && (
                <Button
                  color="primary"
                  variant="outlined"
                  disableElevation
                  onClick={handleBack}
                  sx={{
                    height: 'fit-content',
                    paddingY: { xs: '5px', sm: '9px' },
                    paddingX: { xs: 2, sm: 3 },
                  }}
                >
                  {t('actions.previous')}
                </Button>
              )}
              {hasNextStep && (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={() => handleNext(0)}
                  sx={{ paddingY: { xs: '6px', sm: '10px' }, paddingX: { xs: 2, sm: 3 } }}
                >
                  {stepper[activeStepIndex].finishStep ? t('actions.done') : t('actions.next')}
                </Button>
              )}
            </Container>
          </Box>
        </Box>
      </Box>
    </Sentry.ErrorBoundary>
  );
};

type ActionContextType = {
  handleNext: (option?: number) => never;
  handleBack: () => never;
  isFirstStep: boolean;
  isLastStep: boolean;
};
export const useActions = () => useOutletContext<ActionContextType>();

export default TutorialStepController;
