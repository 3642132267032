import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useBanners } from '@eposnow/ui-core';
import NotFound from './screens/error/NotFound';
import Addresses from './steps/addresses/Addresses';
import StepController from './components/StepController';
import BusinessInfo from './steps/businessInfo/BusinessInfo';
import Complete from './steps/complete/Complete';
import Callback from './screens/callback/Callback';
import Verification from './steps/verification/Verification';
import TerminalStep from './steps/terminal/TerminalStep';
import Maintenance from './screens/maintenance/Maintenance';
import NoSteps from './screens/noSteps/NoSteps';
import Tutorials from './steps/tutorials/Tutorials';
import saturnProPlusSteps from './steps/config/tutorials/saturnProPlusSteps';
import saturnProSteps from './steps/config/tutorials/saturnProSteps';
import proSteps from './steps/config/tutorials/ProV400';
import linkSteps from './steps/config/tutorials/LinkP400';
import ams1StandaloneSteps from './steps/config/tutorials/Ams1Standalone';
import ams1ProSteps from './steps/config/tutorials/Ams1Pro';
import TutorialStepController from './components/TutorialStepController';
import PayoutSettings from './steps/paymentsConfiguration/PayoutSettings';
import { UIContext } from './context/UIContext';
import { UserContext } from './context/UserContext';
import { isWithinTimeInterval } from './helpers/helpers';

const AppRoutes = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { locale } = useContext(UserContext);
  const { isMobile } = useContext(UIContext);

  const { banners, BannersComponent } = useBanners({
    warning: {
      type: 'warning',
      title: t('externalMaintenance.banner'),
    },
  });

  return (
    <>
      {isWithinTimeInterval(
        Date.now(),
        parseInt(process.env.REACT_APP_SHOW_MAINTENANCE_BANNER_START, 10),
        parseInt(process.env.REACT_APP_SHOW_MAINTENANCE_BANNER_END, 10)
      ) && (
          <BannersComponent
            banners={banners}
            theme={theme}
            openSideNav={false}
            showSideNav={false}
            locale={locale}
            isMobile={isMobile}
          />
        )}
      <Routes>
        <Route element={<StepController />}>
          <Route path="/" element={null} />
          <Route path="/payments-business" element={<BusinessInfo />} />
          <Route path="/payments-location" element={<Addresses />}>
            <Route path=":addressId" element={null} />
          </Route>
          <Route path="/payments-complete" element={<Complete />} />
          <Route path="/payments-verification" element={<Verification />} />
          <Route path="/payments-configuration" element={<PayoutSettings />} />
          <Route path="/payments-terminal" element={<TerminalStep />} />
          <Route path="/complete" element={<Complete />} />
        </Route>
        <Route path="/tutorials" element={<Tutorials />} />
        {[
          saturnProPlusSteps,
          saturnProSteps,
          proSteps,
          linkSteps,
          ams1StandaloneSteps,
          ams1ProSteps,
        ].map((config) => (
          <Route key={config.header.title} element={<TutorialStepController stepConfig={config} />}>
            {config.stepper.map(({ stepKey, stepOrder, component }) => (
              <Route key={stepOrder} path={stepKey} element={component} />
            ))}
          </Route>
        ))}
        <Route path="/no-steps" element={<NoSteps />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/callback" element={<Callback />} />
      </Routes>
    </>
  );
};
export default AppRoutes;
