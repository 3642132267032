import React from "react";

type HTMLFieldSetElementProps = React.DetailedHTMLProps<
  React.FieldsetHTMLAttributes<HTMLFieldSetElement>,
  HTMLFieldSetElement
>;

export interface NotchedOutlineProps extends HTMLFieldSetElementProps {
  label?: React.ReactNode;
}

const NotchedOutline = React.forwardRef<
  HTMLFieldSetElement,
  NotchedOutlineProps
>((props, ref) => {
  const { label, ...fieldsetProps } = props;

  return (
    <fieldset {...fieldsetProps} ref={ref}>
      <legend>{label && <span>{label}</span>}</legend>
    </fieldset>
  );
});

export default NotchedOutline;
