const terminalNames = [
  {
    model: 'P400Plus',
    name: 'screens.terminal.eposNowPaymentsLink',
  },
  {
    model: 'S1F2',
    name: 'screens.terminal.eposNowPaymentsProPlus',
  },
  {
    model: 'V400m',
    name: 'screens.terminal.eposNowPaymentsPro',
  },
  {
    model: 'AMS1',
    name: 'screens.terminal.eposNowPaymentsAir',
  },
];

export default terminalNames;
