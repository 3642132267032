import * as React from "react";
import {useContext} from "react";
import {Alert, Box, Button, IconButton, Typography} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {BannerContext} from "../context/BannerContext";
import {UIContext} from "../context/UIContext";

const Banners = () => {
    const { banners } = useContext(BannerContext);
    const { t } = useTranslation();
    const { isMobile } = useContext(UIContext);
    const theme = useTheme();
    const bannerHeight = isMobile ? 96 : 68;


    return <><Box sx={{ position: "fixed", left: 0, top: isMobile ? "56px" : "64px", width: "100%", overflow: "hidden", zIndex: 1000}}>{Object.entries(banners).map(([key, banner]) => <Alert key={key}
        variant="filled"
        severity={banner.bannerType}
        role="alert"
        sx={{
            height: `${bannerHeight}px`,
            scrollMargin: '60px',
            flexGrow: 1,
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
            color: theme.palette[banner.bannerType]['160p'],
            backgroundColor: theme.palette[banner.bannerType]['190p'],
            '.MuiAlert-message': {
                width: '100%',
            },
            '.MuiAlert-action': {
                paddingTop: 0,
            },
            '.MuiAlert-icon': {
                marginTop: "6px",
                padding: '8px 0',
                alignSelf: 'baseline',
                display: isMobile ? 'none' : undefined
            },
            paddingY: 0.5,
        }}
        action={
            banner.onClickButton && <>{!banner.bannerButtonText || isMobile &&
                <IconButton
                    aria-label={t('actions.closeAlert')}
                    color="inherit"
                    size="small"
                    onClick={banner.onClickButton}
                    title={t('actions.closeAlert')}
                >
                    {!banner.bannerIcon ? <CloseOutlinedIcon fontSize="inherit" /> : banner.bannerIcon}
                </IconButton>}
                {banner.bannerButtonText && !isMobile && <Button variant="text" sx={{marginRight: "16px", whiteSpace: "nowrap"}} onClick={banner.onClickButton}>{banner.bannerButtonText}</Button>}
            </>
        }
    >
         <Typography sx={{fontWeight: 600}}>{banner.bannerTitle}</Typography>
         {banner.bannerMessage}
    </Alert>)}</Box><Box sx={{height: `${Object.keys(banners).length * bannerHeight}px`, overflow: "hidden"}}/></>
}

export default Banners;
