import React from 'react';
import { Card, Typography, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';

import { useComplete } from '../../components/StepController';
import { ReactComponent as Success } from '../../img/icons/success.svg';
import { ReactComponent as Warning } from '../../img/icons/warning.svg';

const Complete = () => {
  const { t } = useTranslation();
  const stepContext = useComplete();
  document.title = t('app.pageTitle', { name: t('nav.stepsComplete') });

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingX: { xs: 3, sm: 10 },
        paddingY: { xs: 10, sm: 10 },
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      {stepContext?.noMoreSteps ? <Success /> : <Warning />}
      <Box mt={2}>
        <Typography variant="h1" mb={2}>
          {stepContext?.noMoreSteps
            ? t('screens.complete.congratulations')
            : t('screens.complete.missingSteps')}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          {stepContext?.noMoreSteps
            ? t('screens.complete.payoutSetupFinished')
            : t('screens.complete.contentMissingSteps')}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          {stepContext?.noMoreSteps
            ? t('screens.complete.welcomeHubContinue')
            : t('screens.complete.startTradingMissingSteps')}
        </Typography>
        {stepContext?.noMoreSteps && (
          <Button
            color='primary'
            variant='contained'
            component={Link}
            href={`${process.env.REACT_APP_LINK_ORGANISATIONS}welcome-hub`}
            disableElevation
            target='_self'
            sx={{ marginTop: 4 }}
          >
            {t('screens.complete.backToWelcomeHub')}
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default Complete;
