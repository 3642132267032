import React, { useContext } from 'react';
import { Button, SxProps } from '@mui/material';
import { UIContext } from '../context/UIContext';

const CardButton = ({
  action,
  icon,
  children,
  styles,
}: {
  action?: () => unknown;
  icon: JSX.Element;
  children: JSX.Element;
  styles?: SxProps;
}) => {
  const { colorMode } = useContext(UIContext);

  return (
    <Button
      variant="contained"
      disableElevation
      sx={{
        padding: '24px',
        borderRadius: '8px',
        color: 'primary.main',
        border: (theme) =>
          `1px ${
            colorMode === 'light' ? theme.palette.grey['12p'] : theme.palette.grey['23p']
          } solid`,
        backgroundColor: (theme) => `${theme.palette.secondary.main}`,
        width: '100%',
        height: '100%',
        ':hover': {
          color: 'white',
        },
        ...styles,
      }}
      startIcon={icon}
      onClick={action}
    >
      {children}
    </Button>
  );
};
export default CardButton;
