/* eslint-disable import/no-duplicates */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enGB from './locales/enGB.json';
import enUS from './locales/enUS.json';
import enAU from './locales/enAU.json';
import frFR from './locales/frFR.json';
import esES from './locales/esES.json';

i18n.use(initReactI18next).init({
  lng: 'en-GB',
  fallbackLng: 'en-GB',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    'en-GB': enGB,
    'fr-FR': frFR,
    'en-US': enUS,
    'en-AU': enAU,
    "es-ES": esES,
    "es": esES
  },
});

export default i18n;
/* eslint-enable import/no-duplicates */
