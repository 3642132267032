import React, { useContext } from 'react';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { useTranslation } from 'react-i18next';
import { Address } from '../types/addressTypes';
import { UIContext } from '../../../context/UIContext';

const InfoBox = styled(Card, { shouldForwardProp: (prop) => prop !== 'colorMode' })<{ colorMode?: string }>(({ theme, colorMode }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: ' 16px 16px 0',
  },
  [theme.breakpoints.up('sm')]: {
    padding: '16px 24px 0',
  },
  border: `1px ${colorMode === 'light' ? theme.palette.grey['12p'] : theme.palette.grey['23p']
    } solid`,
  ':hover': {
    cursor: 'pointer',
    backgroundColor: `1px ${theme.palette.primary.main} solid`,
  },
}));

const InfoAddressCard = ({
  address,
  countries,
  clickEditButton,
}: {
  address: Address;
  countries: { value: string; label: string }[];
  clickEditButton: () => void;
}) => {
  const { t } = useTranslation();
  const { colorMode } = useContext(UIContext);
  const countryName =
    countries.find((obj) => obj.value === address.CountryName)?.label || address.CountryName || '';

  return (
    <InfoBox
      onClick={clickEditButton}
      elevation={0}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        minHeight: '280px',
        flex: 1,
      }}
      colorMode={colorMode}
    >
      <Typography marginBottom={0.5} variant="h3">
        {address.Name}
      </Typography>
      {address.Description && <Typography>{address.Description}</Typography>}
      {address.Address1 && <Typography>{address.Address1}</Typography>}
      {address.Address2 && <Typography>{address.Address2}</Typography>}
      <Typography>
        {[address.Town, address.County, address.PostCode].filter(Boolean).join(', ')}
      </Typography>
      <Typography>{countryName}</Typography>
      {address.EmailAddress && <Typography>{address.EmailAddress}</Typography>}
      {address.PhoneNumber && <Typography>{address.PhoneNumber}</Typography>}
      <Box
        sx={{
          width: '100%',
          marginTop: 'auto',
        }}
      >
        <Divider
          sx={{
            margin: {
              xs: '22px -16px 0',
              sm: '22px -24px 0',
            },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '-16px',
            padding: '8px 0',
          }}
        >
          <Button
            type="button"
            sx={{
              color: 'text.secondary',
              ':hover': {
                backgroundColor: colorMode === 'light' ? 'grey.4p' : 'grey.18p',
              },
            }}
            startIcon={<ModeEditOutlinedIcon />}
            onClick={clickEditButton}
          >
            {t('actions.edit')}
          </Button>
        </Box>
      </Box>
    </InfoBox>
  );
};

export default InfoAddressCard;
