import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, IconButton, Link, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useTranslation } from 'react-i18next';
import QRDialog from './QRDialog';

const MobileTutorialsTopBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [showQRAlert, setShowQRAlert] = useState(false);

  return (
    <Box
      sx={{
        width: '100% !important',
        padding: '0 0.5em',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Link
        rel="noreferrer noopener"
        aria-label={t('screens.tutorials.backToTutorials')}
        title={t('screens.tutorials.backToTutorials')}
        underline="none"
        to={'/tutorials' as any}
        component={RouterLink}
        color="inherit"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ArrowBackIcon sx={{ color: 'icon.default', marginRight: '1rem' }} />

        <Typography variant="h3" color="text.primary">
          {t(
            `steps.tutorials.${location.pathname
              .split('/')
              .at(-1)
              .replace(/-\d.*/, '')
              .replace(/-./g, (x) => x[1].toUpperCase())}.title`
          )}
        </Typography>
      </Link>

      <IconButton
        aria-label={t('steps.tutorials.qrCode.cta')}
        sx={{ padding: 0, color: 'text.secondary' }}
        onClick={() => {
          setShowQRAlert(true);
        }}
      >
        <QrCodeScannerIcon />
      </IconButton>

      {showQRAlert && (
        <QRDialog
          open
          title={t('steps.tutorials.qrCode.cta')}
          description={t('steps.tutorials.qrCode.scanOrVisit')}
          showFullUrlMessage
          primaryButtonText={t('actions.gotIt')}
          primaryAction={() => setShowQRAlert(false)}
          secondaryButtonText={t('actions.support')}
          secondaryAction={() => window.open(process.env.REACT_APP_LINK_SUPPORT)}
          url={document.location.href}
          onClose={() => setShowQRAlert(false)}
          fullscreenDialog
        />
      )}
    </Box>
  );
};

export default MobileTutorialsTopBar;
