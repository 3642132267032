/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { useQuery } from 'react-query';
import { IndustryType } from '../types';
import { UserContext } from '../context/UserContext';

const useIndustryTypes = () => {
  const [industryTypes, setIndustryTypes] = useState<{ value: string; label: string }[]>([]);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const { apiFetch } = useContext(UserContext);

  const {
    refetch: fetchData,
    data,
    isLoading,
  } = useQuery<IndustryType[]>(
    'industryTypes',
    () => apiFetch(`${process.env.REACT_APP_API_V4}company/industrytypes`),
    {
      onSuccess: () => setShowAlertMessage(false),
      onError: (err: any) => {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error fetching industryTypes'), {
          extra: { error: JSON.stringify(err) },
        });
        setShowAlertMessage(true);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setIndustryTypes(
        data.map((type: IndustryType) => ({
          value: type.IndustryTypeId.toString(),
          label: type.Name,
        }))
      );
    }
  }, [data]);

  return { industryTypes, fetchData, isLoading, showAlertMessage };
};

export default useIndustryTypes;

