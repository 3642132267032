import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from '../../../img/tutorials/point-of-sale.png';

const PointOfSale = () => {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      sx={{
        padding: '40px',
      }}
    >
      <Grid container direction="row" alignItems="stretch" spacing={4}>
        <Grid container item direction="row" justifyContent="center" sm="auto">
          <Box
            component="img"
            sx={{
              width: 256.5,
              height: 456,
            }}
            alt="Terminal"
            src={Image}
          />
        </Grid>
        <Grid container item xs={12} sm direction="column" justifyContent="space-between">
          <Box>
            <Typography px={2} pb={1} variant="h2">
              {t('steps.tutorials.pointOfSale.title')}
            </Typography>
            <Typography variant="body1" px={2}>
              {t('steps.tutorials.pointOfSale.paragraph')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PointOfSale;
