import React, { useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SxProps,
  TextField,
} from '@mui/material';
import { DesktopTimePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import isEmailValidator from 'validator/lib/isEmail';
import { FieldHookConfig, useField } from 'formik';
import * as Yup from 'yup';
// eslint-disable-next-line import/no-duplicates
import { parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import 'date-fns/locale';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { matchSorter } from 'match-sorter';
import { UIContext } from '../context/UIContext';
import { dateTodayOrAfter, formatDates, validatePhoneNumber, validatePostCode } from '../helpers/helpers';
import IntlTelField from './IntlTelField';
import { UserContext } from '../context/UserContext';
import { STATE_LIST } from "../constants";

const getAddressValidationSchema = (country: string) => Yup.object({
  Name: Yup.string()
    .trim()
    .max(50, 'validation.lessThan50Chars')
    .required('validation.fieldRequired'),
  Description: Yup.string().trim().max(50, 'validation.lessThan50Chars'),
  Address1: Yup.string()
    .trim()
    .max(50, 'validation.lessThan50Chars')
    .required('validation.fieldRequired'),
  Address2: Yup.string().trim().max(50, 'validation.lessThan50Chars'),
  Town: Yup.string()
    .trim()
    .max(50, 'validation.lessThan50Chars')
    .required('validation.fieldRequired'),
  County: Object.keys(STATE_LIST).includes(country) ? Yup.string().trim().max(50, 'validation.lessThan50Chars').required('validation.fieldRequired') : Yup.string().trim().max(50, 'validation.lessThan50Chars'),
  PostCode: Yup.string()
    .trim()
    .max(10, 'validation.lessThan10Chars')
    .required('validation.fieldRequired')
    .test('postCode', 'validation.invalidPostCode', (val) => validatePostCode(val, country)),
  PhoneNumber: Yup.string()
    .min(5, 'validation.invalidPhoneNumber')
    .max(50, 'validation.lessThan50Chars')
    .required('validation.fieldRequired')
    .test('phoneNumber', 'validation.invalidPhoneNumber', (val) => validatePhoneNumber(val, country)),
  EmailAddress: Yup.string()
    .max(50, 'validation.lessThan50Chars')
    .required('validation.fieldRequired')
    .test(
      'is-valid',
      () => 'validation.validEmail',
      (value) => (value ? isEmailValidator(value) : new Yup.ValidationError('Invalid value'))
    ),
});

const companyInfoValidationSchema = Yup.object({
  organisationName: Yup.string()
    .trim()
    .max(50, 'validation.lessThan50Chars')
    .required('validation.fieldRequired'),
  industryType: Yup.string()
    .max(50, 'validation.lessThan50Chars')
    .required('validation.fieldRequired'),
  startTradingDate: Yup.string().required('validation.fieldRequired'),
});

const terminalValidationSchema = Yup.object({
  UniqueName: Yup.string().trim().max(50, 'validation.lessThan50Chars'),
  LocationId: Yup.string().required('validation.fieldRequired'),
});

const payoutSettingsValidationSchema = Yup.object({
  PayoutTime: Yup.string().required('validation.fieldRequired'),
});

type TextFieldProps = FieldHookConfig<string> & {
  label: string;
  id: string;
  autoComplete?: string;
  formName?: string;
  objectId?: string;
  required?: boolean;
  smallPadding?: boolean;
  type?: string;
  error?: string;
  clearError?: (errorKey: string) => void;
  helperText?: string;
  styles?: SxProps;
};

type SelectFieldProps = FieldHookConfig<string> & {
  label: string;
  id: string;
  autoComplete?: string;
  formName?: string;
  objectId?: string;
  required?: boolean;
  smallPadding?: boolean;
  disableFiltering?: boolean;
  options: {
    value: string;
    label: string;
  }[];
  isLoading?: boolean;
  freeSolo?: boolean;
  loadingText?: string;
  error?: string;
  clearError?: (errorKey: string) => void;
  helperText?: string;
  onTextChange?(...data: any[]): void;
  onSelectionChange?(...data: any[]): void;
};

type SimpleSelectFieldProps = FieldHookConfig<string> & {
  label: string;
  id: string;
  formName?: string;
  objectId?: string;
  required?: boolean;
  options: string[];
  error?: string;
  clearError?: (errorKey: string) => void;
  helperText?: string;
  smallPadding?: boolean;
};

type CheckboxProps = FieldHookConfig<string> & {
  label: string;
  id: string;
  formName?: string;
  objectId?: string;
  required?: boolean;
  type: string;
};

type DatePickerProps = FieldHookConfig<string> & {
  label: string;
  id: string;
  formName?: string;
  objectId?: string;
  required?: boolean;
  type: string;
  disablePastDates?: boolean;
  helperText?: string;
};

const setFieldOnSessionStorage = (
  formName: string,
  objectId: string,
  name: string,
  value: string | boolean | number
) => {
  const savedObjects = JSON.parse(sessionStorage.getItem(formName) || '{}');
  const currentObject = savedObjects[objectId];

  sessionStorage.setItem(
    formName,
    JSON.stringify({
      ...savedObjects,
      [objectId]: { ...currentObject, [name]: value },
    })
  );
};

type TimePickerProps = FieldHookConfig<string> & {
  label: string;
  id: string;
  helperText: string;
  formName?: string;
  objectId?: string;
  required?: boolean;
  inputFormat?: string;
  views?: any[];
  minutesStep?: number;
  shouldDisableTime?: (timeValue: any, clockType: any) => boolean;
};

type PhoneNumberProps = FieldHookConfig<string> & {
  label: string;
  id: string;
  helperText?: string;
  formName?: string;
  objectId?: string;
  required?: boolean;
  type?: string;
  error?: string;
  clearError?: (errorKey: string) => void;
  autoComplete?: string;
  defaultCountry?: string;
  countries?: string[];
};

const FormikPhoneNumber = (props: PhoneNumberProps) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    id,
    formName,
    objectId = '',
    autoComplete = 'on',
    required = false,
    type = 'text',
    error,
    clearError,
    helperText = '',
    defaultCountry = 'gb',
    countries = ['gb']
  } = props;
  const { t } = useTranslation();
  const { setValue } = helpers;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [changedPhoneNumber, setChangedPhoneNumber] = useState(false);
  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (phoneNumber === '') {
      setPhoneNumber(field.value);
    }
    if (changedPhoneNumber) {
      setChangedPhoneNumber(false);
    } else setPhoneNumber(field.value);
  }, [field.value]);

  const handleChangeNumber = (
    isValid: any,
    inputPhoneNumber: any,
    country: any,
    countryNumber: any
  ) => {
    clearError?.(id);
    const formattedPhoneNumber = inputPhoneNumber
      .trim()
      .replace(/\s/g, '')
      .replace(/[a-zA-Z]/g, '');

    setPhoneNumber(formattedPhoneNumber);

    if (first) setFirst(false);
    else setChangedPhoneNumber(true);
    const formattedCountryNumber = countryNumber.replace(/\s/g, '');

    if (formName) {
      setFieldOnSessionStorage(formName, objectId, field.name, formattedCountryNumber);
    }
    setValue(formattedCountryNumber);
  };

  const handleChangeFlag = (
    inputPhoneNumber: any,
    country: any,
    countryNumber: any,
    isValid: any
  ) => {
    clearError?.(id);

    const formattedCountryNumber = countryNumber.replace(/\s/g, '');
    setChangedPhoneNumber(true);
    if (formName) {
      setFieldOnSessionStorage(formName, objectId, field.name, formattedCountryNumber);
    }
    setValue(formattedCountryNumber);
  };

  const handlePhoneNumberBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <IntlTelField
      value={phoneNumber}
      onSelectFlag={handleChangeFlag}
      defaultCountry={defaultCountry}
      onPhoneNumberChange={handleChangeNumber}
      onPhoneNumberBlur={handlePhoneNumberBlur}
      required={required}
      autoComplete={autoComplete}
      fieldId="PhoneNumber"
      fieldName="PhoneNumber"
      label={label}
      preferredCountries={[]}
      onlyCountries={countries}
      autoPlaceholder={false}
      color="error"
      size="small"
      variant="outlined"
      error={Boolean(error) || (meta.touched && Boolean(meta.error))}
      helperText={
        (Boolean(error) && error) ||
        (meta.touched && meta.error && t(meta.error, { field: label })) ||
        helperText
      }
    />
  );
};

const FormikTextField = (props: TextFieldProps) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);
  const {
    label,
    id,
    formName,
    objectId = '',
    autoComplete = 'on',
    required = false,
    type = 'text',
    smallPadding = false,
    error,
    clearError,
    helperText = '',
    styles,
  } = props;
  const { t } = useTranslation();

  const changeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError?.(id);
    if (formName) {
      setFieldOnSessionStorage(formName, objectId, field.name, e.currentTarget.value);
    }
    field.onChange(e);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <TextField
      id={id}
      label={label}
      fullWidth
      autoComplete={autoComplete}
      required={required}
      type={showPassword ? 'text' : type}
      size={smallPadding ? 'small' : 'medium'}
      helperText={
        (Boolean(error) && error) ||
        (meta.touched && meta.error && t(meta.error, { field: label })) ||
        helperText
      }
      {...field}
      onChange={changeField}
      error={Boolean(error) || (meta.touched && Boolean(meta.error))}
      InputProps={{
        endAdornment: (
          <>
            {meta.touched && Boolean(meta.error) && (
              <InputAdornment position="end">
                <WarningAmberIcon color="error" />
              </InputAdornment>
            )}
            {type === 'password' && (
              <InputAdornment position="end">
                <IconButton
                  aria-label={`toggle ${label} visibility`}
                  title={`toggle ${label} visibility`}
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
      sx={{
        marginBottom: { xs: '16px', sm: '24px' },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'grey.23p',
        },
        ...styles,
      }}
    />
  );
};

const CustomPaper = (props: any) => <Paper elevation={8} {...props} />;

const FormikSelect = (props: SelectFieldProps) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    id,
    formName,
    objectId = '',
    autoComplete = 'on',
    required = false,
    options,
    smallPadding = false,
    isLoading = false,
    disableFiltering = false,
    freeSolo = false,
    loadingText = 'errors.loadingOptions',
    error,
    clearError,
    helperText = '',
    onTextChange = () => {
      /* void */
    },
    onSelectionChange = () => {
      /* void */
    },
  } = props;
  const { t } = useTranslation();
  const { setValue } = helpers;
  const [selectedOption, setSelectedOption] = useState<
    { value: string; label: string } | null | string
  >(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [textValue, setTextValue] = React.useState('');

  const inputChangeField = (newInputValue: string) => {
    setTextValue(newInputValue);
    const newOption = options.find(
      (option) => option.label.toLowerCase() === newInputValue.toLowerCase()
    );
    clearError?.(id);
    if (newOption) {
      setValue(newOption.value);
      if (formName) {
        setFieldOnSessionStorage(formName, objectId, field.name, newOption.value);
      }
      setSelectedOption({ ...newOption });
      onSelectionChange(newOption);
    } else if (disableFiltering && newInputValue) {
      setValue(newInputValue);
      if (formName) {
        setFieldOnSessionStorage(formName, objectId, field.name, newInputValue);
      }
      setSelectedOption(newInputValue);
    } else if (!newInputValue) {
      if (formName) {
        setFieldOnSessionStorage(formName, objectId, field.name, '');
      }
      setValue('');
      setSelectedOption(freeSolo ? '' : null);
    }
  };

  useEffect(() => {
    if ((!options || options.length === 0) && !freeSolo) return;

    if (field.value !== textValue) {
      let newValue = field.value;
      const newOption = options.find(
        (option) =>
          option.label?.toLowerCase() === newValue?.toLowerCase() ||
          option.value?.toLowerCase() === newValue?.toLowerCase()
      );
      if (newOption) newValue = newOption.label;
      inputChangeField(newValue);
    }
  }, [field.value]);

  useEffect(() => {
    if (!options || options.length === 0) return;
    let newValue = field.value;
    const newOption = options.find(
      (option) =>
        option.value?.toLowerCase() === field.value?.toLowerCase() ||
        option.label?.toLowerCase() === field.value?.toLowerCase()
    );
    if (newOption) newValue = newOption.label;
    if (newValue !== textValue) inputChangeField(newValue);
  }, [options?.length]);

  return (
    <Autocomplete
      fullWidth
      freeSolo={freeSolo}
      id={id}
      loading={isLoading}
      loadingText={t(loadingText)}
      noOptionsText={t('errors.noOptions')}
      options={options}
      inputValue={textValue}
      onInputChange={(e, newInputValue) => {
        if (!e) return;
        inputChangeField(newInputValue);
      }}
      isOptionEqualToValue={(option, value) =>
        (typeof option === 'string' ? option : option.value) ===
        (typeof value === 'string' ? value : value.value)
      }
      open={focused && isOpen}
      onFocus={() => {
        setFocused(true);
        setIsOpen(true);
      }}
      onOpen={(e) => {
        setIsOpen(true);
        onTextChange();
        if (field.value) onTextChange(e as any);
      }}
      onClose={() => setIsOpen(false)}
      onBlur={() => {
        onTextChange();
        setFocused(false);
      }}
      filterOptions={(opts, { inputValue }) =>
        disableFiltering || !inputValue
          ? opts
          : matchSorter(opts, inputValue, { keys: ['value', 'label'] })
      }
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
      onChange={(_e, value, details) => {
        if (details === 'clear') {
          onTextChange();
          inputChangeField('');
        }
      }}
      includeInputInList
      value={selectedOption}
      PaperComponent={CustomPaper}
      renderInput={(params) => (
        <TextField
          {...params}
          {...field}
          onChange={(e) => {
            onTextChange(e as any);
          }}
          label={label}
          InputProps={{
            ...params.InputProps,
            inputProps: { ...params.inputProps, autoComplete },
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    sx={{ color: 'text.secondary' }}
                    aria-label="Loading Data"
                    aria-busy={isLoading}
                    aria-live="polite"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          required={required}
          size={smallPadding ? 'small' : 'medium'}
          helperText={
            (Boolean(error) && error) ||
            (meta.touched && meta.error && t(meta.error, { field: label })) ||
            helperText
          }
          error={Boolean(error) || (meta.touched && Boolean(meta.error))}
          sx={{
            marginBottom: { xs: '16px', sm: '24px' },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'grey.23p',
            },
          }}
        />
      )}
    />
  );
};

const FormikSimpleSelect = (props: SimpleSelectFieldProps) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    id,
    formName,
    objectId = '',
    required = false,
    options,
    error,
    clearError,
    helperText = '',
    smallPadding,
  } = props;
  const { t } = useTranslation();
  const { setValue } = helpers;

  const ITEM_HEIGHT = 32;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5.5,
        width: 250,
      },
    },
  };

  const changeField = (value: string | null) => {
    clearError?.(id);
    const newValue = value || '';
    if (formName) {
      setFieldOnSessionStorage(formName, objectId, field.name, newValue);
    }
    setValue(newValue);
  };

  return (
    <FormControl
      required={required}
      sx={{ m: smallPadding ? 0 : 1, minWidth: 300 }}
      error={Boolean(error) || (meta.touched && Boolean(meta.error))}
      size={smallPadding ? 'small' : 'medium'}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        id={id}
        labelId={`${id}-label`}
        onChange={(e) => {
          changeField(e.target.value);
        }}
        value={field.value}
        label={label}
        MenuProps={MenuProps}
      >
        {options.map((option: string) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {(Boolean(error) && error) ||
          (meta.touched && meta.error && t(meta.error, { field: label })) ||
          helperText}
      </FormHelperText>
    </FormControl>
  );
};

const FormikCheckbox = (props: CheckboxProps) => {
  const [field] = useField(props);
  const { label, id, formName, objectId = '', required = false } = props;

  const changeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formName) {
      setFieldOnSessionStorage(formName, objectId, field.name, e.currentTarget.checked);
    }
    field.onChange(e);
  };

  return (
    <FormControlLabel
      control={<Checkbox id={id} required={required} {...field} onChange={changeField} />}
      label={label}
    />
  );
};

const FormikDatePicker = (props: DatePickerProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(props);
  const {
    label,
    id,
    formName,
    objectId = '',
    required = false,
    disablePastDates = true,
    helperText = '',
  } = props;
  const { isMobile } = useContext(UIContext);
  const { localeFns } = useContext(UserContext);
  const { setValue } = helpers;
  const [dateError, setDateError] = useState('');
  const [changeSessionStorage, setChangeSessionStorage] = useState(false);
  const [inputValueChanged, setInputValueChanged] = useState(false);

  useEffect(() => {
    if (!meta.touched && !inputValueChanged) return;

    if (field.value) {
      const validDate = field.value.toString() !== 'Invalid Date';
      if (validDate) {
        const date = new Date(field.value);
        if (dateTodayOrAfter(formatDates(date, new Date()))) {
          if (formName && changeSessionStorage) {
            setChangeSessionStorage(false);
            setFieldOnSessionStorage(formName, objectId, field.name, date.toJSON());
          }
          setDateError('');
          setValue(date.toJSON());
        } else {
          setDateError(t('validation.dateGreaterThanToday'));
          setValue(date.toJSON());
        }
      } else {
        setDateError(t('validation.invalidDate'));
        setValue(field.value.toString());
      }
    } else {
      setDateError(required ? t('validation.fieldRequired', { field: label }) : '');
      setValue('');
      if (formName && changeSessionStorage) {
        setChangeSessionStorage(false);
        setFieldOnSessionStorage(formName, objectId, field.name, '');
      }
    }
  }, [field.value]);

  const changeField = (value: string | null) => {
    setChangeSessionStorage(true);
    setInputValueChanged(true);
    setValue(value || '');
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeFns}>
      {isMobile && (
        <MobileDatePicker
          label={label}
          value={field.value === '' ? null : parseISO(field.value)}
          onChange={changeField}
          disablePast={disablePastDates}
          renderInput={(params) => (
            <TextField
              id={id}
              required={required}
              {...params}
              helperText={(Boolean(dateError) && dateError) || helperText}
              error={Boolean(dateError)}
              sx={{
                marginBottom: { xs: '16px', sm: '24px' },
              }}
            />
          )}
        />
      )}
      {!isMobile && (
        <DesktopDatePicker
          label={label}
          value={field.value === '' ? null : parseISO(field.value)}
          onChange={changeField}
          disablePast={disablePastDates}
          renderInput={(params) => (
            <TextField
              id={id}
              required={required}
              {...params}
              helperText={(Boolean(dateError) && dateError) || helperText}
              error={Boolean(dateError)}
              sx={{
                marginBottom: { xs: '16px', sm: '24px' },
              }}
            />
          )}
        />
      )}
    </LocalizationProvider>
  );
};

const FormikTimePicker = (props: TimePickerProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(props);
  const {
    label,
    id,
    formName,
    helperText,
    objectId = '',
    required = false,
    inputFormat = 'HH:mm',
    views = ['hours', 'minutes'],
    minutesStep = 1,
    shouldDisableTime,
  } = props;
  const { isMobile } = useContext(UIContext);
  const { setValue } = helpers;
  const [timeError, setTimeError] = useState('');
  const [changeSessionStorage, setChangeSessionStorage] = useState(false);
  const [inputValueChanged, setInputValueChanged] = useState(false);

  useEffect(() => {
    if (!meta.touched && !inputValueChanged) return;

    if (field.value) {
      const validTime = field.value.toString() !== 'Invalid Date';
      setTimeError(validTime ? '' : t('validation.invalidTime'));
      const formattedTime = new Date(field.value).toJSON();

      if (formName && validTime && changeSessionStorage) {
        setChangeSessionStorage(false);
        const savedObjects = JSON.parse(sessionStorage.getItem(formName) || '{}');
        const currentObject = savedObjects[objectId];

        const newDate = new Date(formattedTime);
        const utcOffset = newDate.getTimezoneOffset() / 60;
        const time = newDate.toLocaleTimeString('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
        });

        sessionStorage.setItem(
          formName,
          JSON.stringify({
            ...savedObjects,
            [objectId]: { ...currentObject, [id]: time, UtcOffset: utcOffset },
          })
        );
      }

      setValue(validTime ? formattedTime : field.value.toString());
    } else {
      setTimeError(required ? t('validation.fieldRequired', { field: label }) : '');
      setValue('');
      if (formName && changeSessionStorage) {
        setChangeSessionStorage(false);
        setFieldOnSessionStorage(formName, objectId, field.name, '');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const changeField = (value: string | null) => {
    setChangeSessionStorage(true);
    setInputValueChanged(true);
    setValue(value || '');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isMobile && (
        <MobileTimePicker
          label={label}
          value={field.value === '' ? null : parseISO(field.value)}
          onChange={changeField}
          ampm={false}
          inputFormat={inputFormat}
          views={views}
          minutesStep={minutesStep}
          renderInput={(params) => (
            <TextField
              id={id}
              required={required}
              sx={{ minWidth: '281px' }}
              {...params}
              helperText={(Boolean(timeError) && timeError) || helperText}
              error={Boolean(timeError)}
            />
          )}
          shouldDisableTime={shouldDisableTime}
        />
      )}
      {!isMobile && (
        <DesktopTimePicker
          label={label}
          value={field.value === '' ? null : parseISO(field.value)}
          onChange={changeField}
          ampm={false}
          inputFormat={inputFormat}
          views={views}
          minutesStep={minutesStep}
          renderInput={(params) => (
            <TextField
              id={id}
              required={required}
              sx={{ minWidth: '281px' }}
              {...params}
              helperText={(Boolean(timeError) && timeError) || helperText}
              error={Boolean(timeError)}
            />
          )}
          shouldDisableTime={shouldDisableTime}
        />
      )}
    </LocalizationProvider>
  );
};

export {
  getAddressValidationSchema,
  companyInfoValidationSchema,
  terminalValidationSchema,
  payoutSettingsValidationSchema,
  FormikTextField,
  FormikSelect,
  FormikSimpleSelect,
  FormikCheckbox,
  FormikDatePicker,
  FormikTimePicker,
  FormikPhoneNumber,
};
