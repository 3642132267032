import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from 'react-query';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { AlertMessage } from '@eposnow/ui-core';
import { getAddressValidationSchema } from '../../../components/FormikComponents';
import AddressForm from './AddressForm';
import { Address, AddressErrors } from '../types/addressTypes';
import { UserContext } from '../../../context/UserContext';
import { UIContext } from '../../../context/UIContext';
import { useUserCountry } from '../../../helpers/useCountries';
import DrawerTitlebar from '../../../components/DrawerTitlebar';

const emptyAddress = {
  Id: 0,
  Name: '',
  Description: '',
  PhoneNumber: '',
  Address1: '',
  Address2: '',
  County: '',
  Town: '',
  PostCode: '',
  CountryName: '',
  EmailAddress: '',
};

const CreateEditAddressCard = ({
  currentAddress,
  resetAddress,
  setCreatedNewAddress,
  setSuccessAddressMessage,
  errorAddressMessage,
  setErrorAddressMessage,
  errors,
  setErrors,
  mode,
}: {
  currentAddress: boolean | Address | undefined;
  resetAddress: () => void;
  setCreatedNewAddress: (val: boolean) => void;
  setSuccessAddressMessage: (message: string) => void;
  errorAddressMessage: string;
  setErrorAddressMessage: (message: string) => void;
  errors: AddressErrors;
  setErrors: (obj: any) => void;
  mode: string;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { apiFetch, locale } = useContext(UserContext);
  const { iOS } = useContext(UIContext);
  const queryClient = useQueryClient();
  const [initialAddress, setInitialAddress] = useState<Address>(emptyAddress);
  const [addressId, setAddressId] = useState<number>(0);
  const { userCountry, isLoading: isLoadingUserCountry } = useUserCountry();

  const removeSavedAddress = () => {
    const savedAddresses = JSON.parse(sessionStorage.getItem('addresses') || '{}');
    delete savedAddresses[`${addressId}`];
    sessionStorage.setItem('addresses', JSON.stringify(savedAddresses));
  };

  useEffect(() => {
    const savedAddresses = JSON.parse(sessionStorage.getItem('addresses') || '{}');
    const newAddressId = mode === 'create' ? 0 : (currentAddress as Address).Id;
    setAddressId(newAddressId);
    const savedAddress = savedAddresses[`${newAddressId}`];

    if (savedAddress?.Id === newAddressId && newAddressId === 0) {
      setInitialAddress(savedAddress);
    } else {
      let newInitialAddress = emptyAddress;
      if (mode === 'create')
        sessionStorage.setItem(
          'addresses',
          JSON.stringify({ ...savedAddresses, [`${newAddressId}`]: newInitialAddress })
        );
      else {
        const parsedCurrentAddress = currentAddress as Address;
        newInitialAddress = {
          ...parsedCurrentAddress,
          Id: parsedCurrentAddress.Id,
          Name: parsedCurrentAddress.Name || '',
          Description: parsedCurrentAddress.Description || '',
          Address1: parsedCurrentAddress.Address1 || '',
          PhoneNumber: parsedCurrentAddress.PhoneNumber || '',
          Address2: parsedCurrentAddress.Address2 || '',
          Town: parsedCurrentAddress.Town || '',
          County: parsedCurrentAddress.County || '',
          PostCode: parsedCurrentAddress.PostCode || '',
          CountryName: parsedCurrentAddress.CountryName || '',
          EmailAddress: parsedCurrentAddress.EmailAddress || '',
        };
      }
      setInitialAddress(newInitialAddress);
    }
  }, [currentAddress]);

  const apiFunction = (address: Address) =>
    mode === 'create'
      ? apiFetch(`${process.env.REACT_APP_API_V4}location`, true, address, 'POST')
      : apiFetch(`${process.env.REACT_APP_API_V4}location`, true, address, 'PUT');

  const { isLoading, mutate: apiCall } = useMutation((address: Address) => apiFunction(address), {
    async onSuccess() {
      if (mode === 'create') setCreatedNewAddress(true);
      await queryClient.invalidateQueries('addresses');
      resetAddress();
      removeSavedAddress();

      setSuccessAddressMessage(
        t(`screens.addresses.${mode === 'create' ? 'successCreateAddress' : 'successEditAddress'}`)
      );
    },
    onError(error: any) {
      if ((error as any)?.code === 401) return;
      Sentry.captureException(new Error('Error creating/updating address'), {
        extra: { error: JSON.stringify(error) },
      });
      setErrorAddressMessage(
        t(`errors.${mode === 'create' ? 'errorCreatingAddress' : 'errorUpdatingAddress'}`)
      );
    },
  });

  const validationSchema = useMemo(() => getAddressValidationSchema(userCountry), [userCountry]);

  const formik = useFormik({
    initialValues: {
      ...initialAddress,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (isLoading) return;
      const requestValues = validationSchema.cast(values) as Address;
      actions.setSubmitting(false);
      apiCall({
        ...requestValues,
        CountryName: userCountry,
      });
    },
  });

  const clearError = (errorKey: string) => {
    setErrors(({ [errorKey as keyof AddressErrors]: _value, ...remaining }) => remaining);
  };

  return isLoadingUserCountry ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '96px',
      }}
    >
      <CircularProgress
        aria-label="Loading Data"
        aria-live="polite"
        aria-busy={isLoadingUserCountry}
        data-testid="loading-icon"
        sx={{ color: 'text.secondary' }}
      />
    </Box>
  ) : (
    <Box
      component="div"
      sx={{
        overflow: 'auto',
        ...(!iOS && {
          marginBottom: '65px',
        }),
      }}
    >
      <DrawerTitlebar
        title={t(`screens.addresses.${mode === 'create' ? 'addNewAddress' : 'editAddress'}`)}
        icon={mode === 'create' ? <AddIcon /> : <EditOutlinedIcon />}
        onClose={resetAddress}
      />
      {errorAddressMessage && (
        <AlertMessage
          type="error"
          styles={{ marginTop: 1.5 }}
          action={() => setErrorAddressMessage('')}
          iconAlignment="baseline"
          locale={locale}
          theme={theme}
        >
          {errorAddressMessage}
        </AlertMessage>
      )}
      <Box sx={{ padding: 2, paddingTop: 0 }}>
        <AddressForm
          formik={formik}
          cancelAction={() => {
            resetAddress();
            removeSavedAddress();
          }}
          objectId={`${addressId}`}
          isSubmitting={isLoading}
          errors={errors}
          clearError={clearError}
        />
      </Box>
    </Box>
  );
};

export default CreateEditAddressCard;
