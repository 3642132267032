import { Step } from '../../types';

export type StepConfig = {
  id: string;
  header: string;
  description: string;
  title: string;
  required: boolean;
  errorMessage?: string;
};

export type TutorialStepConfig = {
  header: {
    title: string,
    subtitle: string,
    image: string
  },
  stepper: Array<{
    title: string,
    pointsTo: {
      next: string[],
      previous: string
    },
    component: any,
    finishStep?: boolean
  } & Step>
};

export const stepConfigs: StepConfig[] = [
  {
    id: 'payments-business',
    header: 'businessInfo.header',
    description: 'businessInfo.description',
    title: 'businessInfo.title',
    required: true,
    errorMessage: 'businessInfo.errorMessage',
  },
  {
    id: 'payments-location',
    header: 'locations.header',
    description: 'locations.description',
    title: 'locations.title',
    required: true,
    errorMessage: 'locations.errorMessage',
  },
  {
    id: 'payments-verification',
    header: 'verification.header',
    description: 'verification.description',
    title: 'verification.title',
    required: true,
    errorMessage: 'verification.errorMessage',
  },
  {
    id: 'payments-configuration',
    header: 'configuration.header',
    description: 'configuration.description',
    title: 'configuration.title',
    required: true,
    errorMessage: 'configuration.errorMessage',
  },
  {
    id: 'payments-terminal',
    header: 'terminal.header',
    description: 'terminal.description',
    title: 'terminal.title',
    required: true,
    errorMessage: 'terminal.errorMessage',
  },
];

export const getStepConfig = (step?: Step) => {
  let currentStep = stepConfigs[0] as StepConfig;

  if (!step) return currentStep;

  stepConfigs.forEach((item) => {
    if (item.id === step.stepKey) {
      currentStep = item;
    }
  });

  return currentStep;
};

export default stepConfigs;

