import React, { useContext } from 'react';
import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import Image from '../../../img/tutorials/network-2.png';
import { UserContext } from '../../../context/UserContext';

const NetworkWireless = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { locale } = useContext(UserContext);

  return (
    <Card
      variant="outlined"
      sx={{
        padding: '40px',
      }}
    >
      <Grid container direction="row" alignItems="stretch" spacing={4}>
        <Grid container item direction="row" justifyContent="center" sm="auto">
          <Box
            component="img"
            sx={{
              width: 256,
              height: 220.7,
            }}
            alt="Terminal"
            src={Image}
          />
        </Grid>
        <Grid container item xs={12} sm direction="column" justifyContent="space-between">
          <Box>
            <Typography px={2} pb={2} variant="h2">
              {t('steps.tutorials.network.title')}
            </Typography>

            <AlertMessage
              isServerAlert
              styles={{ marginBottom: 2, padding: { xs: '4px 8px', md: '8px 16px' } }}
              type="warning"
              iconAlignment="baseline"
              locale={locale}
              theme={theme}
            >
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  lineHeight: 1.5,
                  marginTop: '-2px',
                  marginBottom: '4px',
                }}
              >
                <Trans i18nKey="steps.tutorials.alerts.warningTitle" />
              </Typography>
              <Typography variant="body2">
                <Trans i18nKey="steps.tutorials.network.warningParagraph" />
              </Typography>
            </AlertMessage>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NetworkWireless;
