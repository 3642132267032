import React, { useContext } from 'react';
import { Box, CircularProgress, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../img/eposnow-logo-small.svg';
import { ReactComponent as WhiteLogo } from '../img/eposnow-logo-small-white.svg';
import { UIContext } from '../context/UIContext';

const AuthenticatingSpinner = () => {
  const { t } = useTranslation();
  const { colorMode, isMobile } = useContext(UIContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
        ...(isMobile && {
          paddingX: 2,
        }),
      }}
    >
      <SvgIcon inheritViewBox>{colorMode === 'light' ? <Logo /> : <WhiteLogo />}</SvgIcon>
      <Typography mt={2} mb={2} variant="h2">
        {t('auth.loggingIn')}
      </Typography>
      <CircularProgress
        aria-label="Loading Data"
        aria-live="polite"
        data-testid="loading-icon"
        sx={{ color: 'text.secondary' }}
      />
    </Box>
  );
}

export default AuthenticatingSpinner;