import React, { useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Error } from '../img/error.svg';
import { UIContext } from '../context/UIContext';

const ErrorLoadingData = ({ action, large = true }: { action: () => void; large?: boolean }) => {
  const { t } = useTranslation();
  const { isSmallDevice, isMobile } = useContext(UIContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: large ? 10 : 1,
        ...(isMobile && {
          paddingX: 2,
        }),
      }}
    >
      <Error />
      <Typography mt={2} variant={large ? 'h2' : 'h3'}>
        {t('components.error.failedToLoad')}
      </Typography>
      <Typography
        sx={{ marginTop: large ? 2 : 1, marginBottom: large ? 3 : 2, color: 'text.secondary' }}
      >
        {t('components.error.failedMessage')}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        type="button"
        disableElevation
        onClick={() => action()}
        sx={{ ...((isSmallDevice || !large) && { width: large ? '100%' : '50%' }) }}
      >
        {t('components.error.retry')}
      </Button>
    </Box>
  );
};

export default ErrorLoadingData;
