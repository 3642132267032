/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import getCountries from '../api/countries';
import { UserContext, isInRole, userRoles } from '../context/UserContext';
import { PRELIM_COUNTRIES } from '../types';

const useCountries = () => {
  const [countries, setCountries] = useState<{ value: string; label: string }[]>([]);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const { t } = useTranslation();

  const {
    refetch: fetchData,
    data: dataCountries,
    isLoading,
  } = useQuery('countries', getCountries, {
    onSuccess: () => setShowAlertMessage(false),
    onError: (err: any) => {
      if ((err as any)?.code === 401) return;
      Sentry.captureException(new Error('Error fetching countries'), {
        extra: { error: JSON.stringify(err) },
      });
      setShowAlertMessage(true);
    },
  });

  useEffect(() => {
    if (dataCountries) {
      setCountries(
        dataCountries.map((country: { isoCode2: string; name: string }) => ({
          value: country.isoCode2,
          label: t([`countries.${country.isoCode2}`, `countries.${country.name}`, country.name]),
        }))
      );
    }
  }, [dataCountries]);

  return { countries, fetchData, isLoading, showAlertMessage };
};

export const useUserCountry = () => {
  const { countries, isLoading: isLoadingCountries } = useCountries();
  const { userCountry, retryUserCountry, isLoadingUserCountry, userModules } = useContext(UserContext);
  const [userCountryName, setUserCountryName] = useState('Loading');

  useEffect(() => {
    if (countries.length === 0) return;
    const maybeUserCountry = countries.find((it) => it.value === userCountry);
    if (maybeUserCountry) {
      setUserCountryName(maybeUserCountry.label);
      Sentry.setTag('territory', userCountry);
    } else {
      setUserCountryName('Unknown');
    }
  }, [userCountry, countries]);
  const termsKey = `REACT_APP_${userCountry}_TERMS`;
  let termsLink = process.env.REACT_APP_UK_TERMS;
  if (termsKey in process.env) {
    termsLink = process.env[termsKey];
  }
  return {
    userCountry,
    userCountryName,
    termsLink,
    isLoading: isLoadingCountries || isLoadingUserCountry || userCountryName === 'Loading',
    isError: !isLoadingCountries && userCountryName === 'Unknown',
    retryUserCountry,
    isHop: !PRELIM_COUNTRIES.includes(userCountry) || isInRole(userModules, userRoles.moduleStandalone),
    isPrelim: PRELIM_COUNTRIES.includes(userCountry) && !isInRole(userModules, userRoles.moduleStandalone),
    helpScreenAvailable: ["GB", "US", "AU"].includes(userCountry),
  };
};

export default useCountries;
