import React from 'react'
import Image from '../../../img/tutorials/saturn-pro-plus.png'
import Network from '../../tutorials/steps/Network'
import YourStore from '../../tutorials/steps/YourStore';
import PointOfSale from '../../tutorials/steps/PointOfSale';
import PointOfSale2 from '../../tutorials/steps/PointOfSale2';
import TillFront from '../../tutorials/steps/TillFront';
import Cellular from '../../tutorials/steps/Cellular';
import PPComplete from '../../tutorials/steps/Complete';
import { TutorialStepConfig } from '../steps';

const addPath = (stepKey: string) => `/tutorials/saturn-pro-plus/${stepKey}`

export default {
    header: {
        title: 'Epos Now Payment Pro+',
        subtitle: 'Saturn S1F2',
        image: Image
    },
    stepper: [
        {
            stepKey: addPath(`network`),
            stepOrder: 0,
            title: 'steps.tutorials.network.title',
            pointsTo: { next: [addPath(`your-store`)], previous: ''},
            component: <Network />
        },
        {
            stepKey: addPath(`your-store`),
            stepOrder: 1,
            title: 'steps.tutorials.yourStore.title',
            pointsTo: { next: [addPath(`point-of-sale`)], previous: addPath(`network`)},
            component: <YourStore />
        },
        {
            stepKey: addPath(`point-of-sale`),
            stepOrder: 2,
            title: 'steps.tutorials.pointOfSale.title',
            pointsTo: { next: [addPath(`point-of-sale-2`)], previous: addPath(`your-store`)},
            component: <PointOfSale/>
        },
        {
            stepKey: addPath(`point-of-sale-2`),
            stepOrder: 3,
            title: 'steps.tutorials.pointOfSale.title',
            pointsTo: { next: [addPath(`point-of-sale-3`)], previous: addPath(`point-of-sale`)},
            component: <PointOfSale2/>
        },
        {
            stepKey: addPath(`point-of-sale-3`),
            stepOrder: 5,
            title: 'steps.tutorials.pointOfSale.title',
            pointsTo: { next: [addPath(`cellular`)], previous: addPath(`point-of-sale-2`)},
            component: <TillFront/>
        },
        {
            stepKey: addPath(`cellular`),
            stepOrder: 6,
            title: 'steps.tutorials.cellular.title',
            pointsTo: { next: [addPath(`cellular-2`)], previous: addPath(`point-of-sale-3`)},
            component: <Cellular/>
        },
        {
            stepKey: addPath(`cellular-2`),
            stepOrder: 7,
            title: 'steps.tutorials.cellular.title',
            finishStep: true,
            pointsTo: { next: ['/tutorials'], previous: addPath(`cellular`)},
            component: <PPComplete/>
        }
    ]
} as TutorialStepConfig;