import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const Callback = () => {
  const [backTo, setBackTo] = useState('/');
  useEffect(() => {
    const redirect = localStorage.getItem('redirect');
    if (redirect) {
      localStorage.removeItem('redirect');
      setBackTo(redirect);
    }
  }, []);

  return <Navigate to={backTo} />;
};

export default Callback;

