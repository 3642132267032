import * as React from "react";
import {Box, Card, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSteps} from "../../../components/StepController";
import {sanitizeAlphanumeric} from "../../../helpers/helpers";

const ProvideSoleTraderName = () => {
    const {t} = useTranslation();
    const stepContext = useSteps();

    return <Card variant="outlined" sx={{padding: "24px"}}>
        <Typography component="h2" variant="h2" sx={{marginBottom: "8px"}}>{t("screens.verification.kycPreparation.additionalInfoSoleTrader.title")}</Typography>
        <Typography sx={{marginBottom: "24px", color: "text.secondary"}}>{t("screens.verification.kycPreparation.additionalInfoSoleTrader.description")}</Typography>
        <Box sx={{display: "flex", flexDirection: "row", gap: "20px"}}>
        <TextField
            required
            label={t("screens.verification.kycPreparation.additionalInfoSoleTrader.firstName")}
            onChange={(ev) => stepContext.setFirstName(sanitizeAlphanumeric(ev.target.value))}
            onBlur={(ev) => stepContext.setFirstName(stepContext.firstName.trim())}
            value={stepContext.firstName}
            inputProps={{maxLength: 255}}
        />
        <TextField
            required
            label={t("screens.verification.kycPreparation.additionalInfoSoleTrader.lastName")}
            onChange={(ev) => stepContext.setLastName(sanitizeAlphanumeric(ev.target.value))}
            onBlur={(ev) => stepContext.setLastName(stepContext.lastName.trim())}
            value={stepContext.lastName}
            inputProps={{maxLength: 255}}
        />
        </Box>
    </Card>;
}

export default ProvideSoleTraderName;
