import React from 'react';
import {
  Typography,
  Card,
  Button,
  CardContent,
  CardActions,
  Divider,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MainHeader } from '@eposnow/ui-core';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          padding: 3,
        }}
      >
        <MainHeader title={t('screens.error.notfound.title')} size="large" theme={theme}>
          <Typography>{t('screens.error.notfound.content')}</Typography>
        </MainHeader>
      </CardContent>
      <Divider />
      <CardActions
        sx={{
          padding: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={() => navigate(-1)}
          sx={{
            marginRight: 1,
          }}
        >
          {t('screens.error.notfound.backButton')}
        </Button>
        <Button color="primary" variant="outlined" component={RouterLink} to="/">
          {t('screens.error.notfound.homeButton')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default NotFound;
