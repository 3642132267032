import React, { useContext } from 'react';
import { Card, Typography, Icon, Box, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { AlertMessage } from '@eposnow/ui-core';
import { UserContext } from '../../../context/UserContext';

const Complete = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { locale } = useContext(UserContext);

  return (
    <Card
      variant="outlined"
      sx={{
        paddingX: { xs: 3, sm: '40px' },
        paddingY: { xs: '40px', sm: '40px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          paddingBottom: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Icon
            sx={{
              p: 0.75,
              width: 'auto',
              height: 'auto',
              marginBottom: '8px',
              borderRadius: '50%',
              border: `2.46px ${theme.palette.success['160p']} solid`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CheckIcon
                sx={{
                  fontSize: 40,
                  padding: 0.5,
                  color: theme.palette.success['160p'],
                  backgroundColor: theme.palette.success['190p'],
                  borderRadius: '50%',
                }}
              />
            </Box>
          </Icon>
        </Box>
        <>
          <Typography variant="h1" mb={2}>
            {t('steps.tutorials.complete.title')}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {t('steps.tutorials.complete.paragraph')}
          </Typography>
        </>
      </Box>
      <AlertMessage
        isServerAlert
        styles={{ marginBottom: 2, padding: { xs: '4px 8px', md: '8px 16px' } }}
        type="info"
        iconAlignment="baseline"
        locale={locale}
        theme={theme}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: 1.5,
            marginTop: '-2px',
            marginBottom: '4px',
          }}
        >
          <Trans i18nKey="steps.tutorials.alerts.infoTitle" />
        </Typography>
        <Typography variant="body2">
          <Trans i18nKey="steps.tutorials.complete.infoParagraph" />
        </Typography>
      </AlertMessage>
    </Card>
  );
};

export default Complete;
